import { api } from '../../_services/Api';
import { articleConstants } from '../_constants';
import { alertActions } from './alert.actions';

export const articleActions = {
  filterArticles,
  getSingleArticle,
  listCategories,
  allArticles
};

function articlesSuccess(payload) {
  return { type: articleConstants.FILTER_ARTICLES_SUCCESS, payload };
}

function allArticles(params) {
  return (dispatch) => {
    // clear alert on location change
    dispatch(alertActions.clear());
    return api.articles
      .filter(params)
      .then((reponse) => {
        dispatch(articlesSuccess({ allArticles: reponse.data }));
      })
      .catch((error) => {
        dispatch(alertActions.error(error.message.toString()));
      });
  };
}

function filterArticles(params) {
  return (dispatch) => {
    // clear alert on location change
    dispatch(alertActions.clear());
    return api.articles
      .filter(params)
      .then((reponse) => {
        dispatch(articlesSuccess({ articles: reponse.data }));
      })
      .catch((error) => {
        dispatch(alertActions.error(error.message.toString()));
      });
  };
}
function getSingleArticle(slug) {
  return (dispatch) => {
    // clear alert on location change
    dispatch(alertActions.clear());
    return api.articles
      .get(slug)
      .then((reponse) => {
        dispatch(articlesSuccess({ singleArticle: reponse.data }));
      })
      .catch((error) => {
        dispatch(alertActions.error(error.message.toString()));
      });
  };
}

function listCategories(params) {
  return (dispatch) => {
    // clear alert on location change
    dispatch(alertActions.clear());
    return api.articles
      .getCategories()
      .then((reponse) => {
        dispatch(articlesSuccess({ Listed_categories: reponse.data }));
      })
      .catch((error) => {
        dispatch(alertActions.error(error.message.toString()));
      });
  };
}
