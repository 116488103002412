/*eslint-disable*/
import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Sidebar({ setNavbarOpen, navbarOpen }) {
  return (
    <>
      <div
        className={
          'absolute right-0 top-16 shadow rounded-tl-2xl rounded-bl-2xl w-2/6  flex-grow items-center bg-white lg:hidden' +
          (navbarOpen ? ' block' : ' hidden')
        }
      >
        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto pb-4">
          {' '}
          {/* <li className="flex items-center m-auto pb-4">
            <button
              className="hover:text-gray-600 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-md uppercase font-bold"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-arrow-alt-circle-down"></i> X
            </button>
          </li> */}
          {/* <li className="flex items-center w-full  px-4  border-l-4 border-primary-green  ">
            
          </li> */}

          <NavLink
              onClick={() => setNavbarOpen(!navbarOpen)}

            exact
            activeClassName="border-l-4 border-primary-green font-bold"
            className="w-full  hover:text-gray-600 text-black px-3 lg:px-6 py-1 mb-2 lg:py-2 ml-auto flex items-center text-center text-xs vxs:text-base"
            to="/"
          >Home
            </NavLink>

          <NavLink
              onClick={() => setNavbarOpen(!navbarOpen)}

            activeClassName="border-l-4 border-primary-green font-bold"
            className="w-full hover:text-gray-600 text-black px-3 lg:px-6 py-1 mb-2  ml-auto lg:py-2 flex items-center text-right text-xs vxs:text-base font-normal "
            to="/about-us"
          >About us
          </NavLink>

          <NavLink
              onClick={() => setNavbarOpen(!navbarOpen)}

            activeClassName="border-l-4 border-primary-green font-bold"
            className="w-full  hover:text-gray-600 text-black px-3 lg:px-6 py-1  mb-2  ml-auto lg:py-2 flex items-center text-center text-xs vxs:text-base font-normal"
            to="/FAQ"
          >FAQ
          </NavLink>

          <NavLink
              onClick={() => setNavbarOpen(!navbarOpen)}

            activeClassName="border-l-4 border-primary-green font-bold"
            className="w-full  hover:text-gray-600 text-black px-3 lg:px-6 py-1  mb-2  ml-auto lg:py-2 flex items-center text-center text-xs vxs:text-base font-normal"
            to="/security"
          >Security
          </NavLink>
          
          <NavLink
              onClick={() => setNavbarOpen(!navbarOpen)}

            activeClassName="border-l-4 border-primary-green font-bold"
            className="w-full  hover:text-gray-600 text-black px-3 lg:px-6 py-1  mb-2  ml-auto lg:py-2 flex items-center text-center text-xs vxs:text-base font-normal"
            to="/Blog"
          >Blog
          </NavLink>
        </ul>
      </div>
    </>
  );
}
