import React, { useEffect, useState } from "react";
import bg1 from "../../assets/img/bg/bg-1.svg";
import bg2 from "../../assets/img/bg/bg-2.svg";
import bg3 from "../../assets/img/bg/bg-3.svg";
import brand1 from "../../assets/img/brand/icea.png";
import brand2 from "../../assets/img/brand/orient-logo.png";
import brand3 from "../../assets/img/brand/etica.webp";
import businessdaily from "../../assets/img/brand/businessdaily.svg";
// import standard from '../../assets/img/brand/standard.svg';
// import techish from '../../assets/img/brand/techish.svg';
import standard from "../../assets/img/brand/KBC.png";
import khusoko from "../../assets/img/brand/khusoko.png";
import KenyaWallScreet from "../../assets/img/brand/The-Kenyan-Wall-Street.svg";
import Frame1638 from "../../assets/img/Frame1638.svg";
import Frame1640 from "../../assets/img/Frame1640_1.png";
import Frame1644 from "../../assets/img/Frame1644.svg";
import FundManagers from "../../assets/img/FUNDMANAGERS.svg";
import Frame1648 from "../../assets/img/Frame1648.svg";
import Frame1649 from "../../assets/img/Frame1649.svg";
import Frame1650 from "../../assets/img/Frame1650.svg";
import Frame1651 from "../../assets/img/Frame1651.svg";
import googlePlay from "../../assets/img/googlePlay.svg";
import { Calculator } from "../../components/Calculator/Calculator";
import CardFeatures from "../../components/cards/CardFeatures";
import Questions from "../../components/Questions/Questions";
import Security from "../../components/Security/Security";
import PlayButton from "../../assets/img/playVideoButton.png";
import CloseButton from "../../assets/img/closeButton.png";
import StoresIcon from "../../assets/img/combo.png";
import AppStoreIcon from "../../assets/img/apple.png";

import YouTube from "react-youtube";
import ReactModal from "react-modal";
import ReactGA from "react-ga";
import { useHistory } from "react-router-dom";

import { RegistrationModal } from "../../components/emailModal/emailmodal";
import Testimonials from "../../components/Testimonials";

const HomePage = () => {
  const history = useHistory();

  const [videoModal, setVideoModal] = React.useState(false);
  // const [AndroidWaitlistModal, setAndroidWaitlistModal] = React.useState(false)
  const [WaitListModal, setWaitListModal] = React.useState(false);

  // const [SubscriberEmail, setSubscriberEmail] = React.useState("")
  // const [SubscriberMobile, setSubscriberMobile] = React.useState("")

  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener("resize", handleResize);
  });

  // function EmailHandler(e) {
  //   setSubscriberEmail(e.target.value)
  // }

  // function PhoneNo_Handler(e) {
  //   setSubscriberMobile(e.target.value)
  // }

  //FUNCTION CALLED BY CHILD "REGISTRATIONMODAL" TO CLOSE THE MODAL
  function ChangeHandler() {
    setWaitListModal(!WaitListModal);
  }

  // console.log("Screen width: " + dimensions.width)
  // const YoutubeWidth = window.innerWidth > 1080 ? (960) : (window.innerWidth - 20)
  // const YoutubeHeight = YoutubeWidth / 1.78

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        preventScroll={true}
        isOpen={videoModal}
        ariaHideApp={false}
        onRequestClose={() => setVideoModal(false)}
        className=" flex-1  h-full    place-items-center"
        style={{
          overlay: {
            backgroundColor: "#00000070",
          },
        }}
      >
        <div>
          {/* <img
              className="absolute bottom-3/4 right-10 h-10 w-10 bg-gray-50 hover:bg-green-500 rounded-lg "
              onClick={() => {
                setVideoModal(!videoModal)
              }}
              src={CloseButton}
              alt="CloseButton"
            /> */}
          <div className="flex h-screen justify-center items-center">
            <svg
              onClick={() => {
                setVideoModal(!videoModal);
              }}
              className="absolute top-32 md:top-1/4 right-3  md:right-10 h-10 w-10 bg-gray-100 hover:bg-white rounded-lg hover:stroke-2 "
              fill="none"
              // viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                transform="translate(-0, -0)"
                class="close-x"
                d="M 10,10 L 30,30 M 30,10 L 10,30"
              />
            </svg>
            <YouTube
              videoId="x78kUEMlD_g"
              opts={{
                // height: 490,
                // width: window.innerWidth - 120,
                height: dimensions.width / 1.78,
                width: dimensions.width > 1080 ? 960 : dimensions.width - 20,
                playerVars: {
                  autoplay: 1,
                },
              }}
              // onReady={this._onReady}
            />
          </div>
        </div>
      </ReactModal>

      <ReactModal
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        preventScroll={false}
        // isOpen={WaitListModal}
        isOpen={WaitListModal}
        ariaHideApp={false}
        className=" h-4/6 mt-20 rounded p-6 shadow-lg bg-white  flex w-10/12 lg:w-1/3 mx-auto  flex-col"
        style={{
          content: {
            position: "absolute",
            top: "40px",
            left: "40px",
            right: "40px",
            bottom: "40px",
            border: "1px solid #ccc",
            // background: '#fff',
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
          },
          overlay: {
            backgroundColor: "#00000070",
          },
        }}
      >
        <RegistrationModal
          visibility={WaitListModal}
          handleChange={ChangeHandler}
        />
      </ReactModal>

      <div className="overflow-x-hidden lg:container  pt-16 md:pt-20 px-5 md:px-8 lg:pl-16 md:pb-8 lg:pb-0 mx-auto flex flex-wrap flex-col md:flex-row items-center relative">
        <div className="absolute left-10 xl:left-2 2xl:left-28 top-36 md:top-20 lg:top-20 xl:top-32  z-100">
          <img
            className="h-40 lg:h-56 xl:h-80 bounce-top-icons"
            src={Frame1638}
            alt="..."
          />
        </div>

        <div className="flex flex-col w-full md:w-3/6  lg:w-3/5 xl:w-3/5 2xl:w-4/6 mt-6 md:mt-14 lg:-mt-10 xl:-mt-28 2xl:-mt-10 xl:px-12 2xl:px-34 justify-center lg:items-start overflow-y-hidden">
          <h1 className="text-2xl tracking-tight font-bold sm:max-w-2xl text-gray-900  md:text-3xl lg:text-5xl text-left slide-in-bottom-h1">
            The modern way to save and grow your money
          </h1>
          <p className="text-base text-gray-500 sm:mt-5  xl:max-w-1.75xl sm:mx-auto mt-4 md:mt-5 md:text-lg lg:mx-0 text-left slide-in-bottom-subtitle">
            Save and invest your money with ease starting with as little as Ksh
            100. Earn 11-16% interests. Set and track progress towards your
            financial goals. Download the app and get started for free in 1 min.
          </p>
          <div className=" flex flex-col md:flex-row w-4/6 md:w-full lg:w-4/6 xl:w-5/6 2xl:w-4/6 mt-6  md:mt-0  justify-start py-2 md:py-6 lg:py-10 text-left fade-in md:space-x-6 space-y-4 md:space-y-0">
            <div className=" w-full md:w-1/2 text-gray-700 flex items-start space-x-4 md:px-2">
              <svg
                width="40"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 8H17C17.2652 8 17.5196 8.10536 17.7071 8.29289C17.8946 8.48043 18 8.73478 18 9V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V9C0 8.73478 0.105357 8.48043 0.292893 8.29289C0.48043 8.10536 0.734784 8 1 8H2V7C2 6.08075 2.18106 5.1705 2.53284 4.32122C2.88463 3.47194 3.40024 2.70026 4.05025 2.05025C4.70026 1.40024 5.47194 0.884626 6.32122 0.532843C7.17049 0.18106 8.08075 0 9 0C9.91925 0 10.8295 0.18106 11.6788 0.532843C12.5281 0.884626 13.2997 1.40024 13.9497 2.05025C14.5998 2.70026 15.1154 3.47194 15.4672 4.32122C15.8189 5.1705 16 6.08075 16 7V8ZM2 10V18H16V10H2ZM8 12H10V16H8V12ZM14 8V7C14 5.67392 13.4732 4.40215 12.5355 3.46447C11.5979 2.52678 10.3261 2 9 2C7.67392 2 6.40215 2.52678 5.46447 3.46447C4.52678 4.40215 4 5.67392 4 7V8H14Z"
                  fill="#20E083"
                />
              </svg>
              <span className="text-base text-gray-500  sm:mx-auto  lg:mx-0 font-medium ">
                Regulated by the CMA
              </span>
            </div>
            <div className="w-4/6 md:w-1/2 text-gray-700 flex  items-start space-x-4 md:px-2 ">
              <svg
                width="40"
                height="13"
                viewBox="0 0 22 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.917 8.00011C11.6623 9.48018 10.8619 10.811 9.67392 11.7298C8.48593 12.6486 6.99666 13.0886 5.50009 12.963C4.00352 12.8374 2.60841 12.1554 1.59016 11.0514C0.57191 9.9475 0.00452288 8.50194 2.5002e-06 7.00011C-0.00137098 5.49472 0.563193 4.04382 1.58168 2.93527C2.60017 1.82672 3.99816 1.14153 5.49828 1.01565C6.9984 0.889774 8.49103 1.3324 9.68002 2.25571C10.869 3.17902 11.6675 4.51555 11.917 6.00011H22V8.00011H20V12.0001H18V8.00011H16V12.0001H14V8.00011H11.917ZM6 11.0001C7.06087 11.0001 8.07828 10.5787 8.82843 9.82854C9.57858 9.0784 10 8.06098 10 7.00011C10 5.93925 9.57858 4.92183 8.82843 4.17169C8.07828 3.42154 7.06087 3.00011 6 3.00011C4.93914 3.00011 3.92172 3.42154 3.17158 4.17169C2.42143 4.92183 2 5.93925 2 7.00011C2 8.06098 2.42143 9.0784 3.17158 9.82854C3.92172 10.5787 4.93914 11.0001 6 11.0001Z"
                  fill="#20E083"
                />
              </svg>
              <span className="text-base text-gray-500  sm:mx-auto  lg:mx-0 font-medium ">
                Bank-level security{" "}
              </span>
            </div>
          </div>
          {/* w-4/6 md:w-3/6 xl:w-4/6 */}
          {/* button icon  */}

          <div className="  flex invisible flex-row md:visible pb-5 space-x-3 cursor-pointer">
            <div
              // onClick={() => { setWaitListModal(!WaitListModal) }}
              onClick={() => {
                window.location.href =
                  "https://www.clkmg.com/Cashletapp/pswebsite";
              }}
              className="  flex flex-row rounded  w-48 justify-end  shadow-lg overflow-hidden border-black border   text-black font-bold"
            >
              <img
                className="h-12 w-17 my-auto justify-center pr-2"
                src={StoresIcon}
              />

              {/* 1_0223 */}
              <div className="flex-column self-center pr-8 ">
                <div className=" align-text-bottom my-auto font-medium tracking-tight text-xs">
                  Get it on
                </div>
                <div className=" align-text-bottom my-auto tracking-tight ">
                  Google Play
                </div>
              </div>
            </div>

            <div
              // onClick={() => { setWaitListModal(!WaitListModal) }}
              onClick={() => {
                window.open(
                  "https://apps.apple.com/us/app/cashlet-savings-investments/id6463010865",
                  "_blank"
                );
              }}
              className="  flex flex-row rounded  w-44 justify-end  shadow-lg overflow-hidden border-black border   text-black font-bold"
            >
              <img
                className="h-7 w-7 mt-2 justify-center mr-5"
                src={AppStoreIcon}
              />

              {/* 1_0223 */}
              <div className="flex-column self-center pr-8 ">
                <div className=" align-text-bottom my-auto font-medium tracking-tight text-xs">
                  Get it on
                </div>
                <div className=" align-text-bottom my-auto tracking-tight ">
                  App Store
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--Right Col--> */}

        <div //PLAYBUTTON
          className="   w-5/6 md:w-3/6 lg:w-2/5 xl:w-2/5  2xl:w-2/6 ml-auto  -mt-84 md:mt-0 lg:mt-0 xl:mt-0 lg:py-8 xl:py-0 relative"
        >
          <img
            className="  relative object-contain h-96  md:h-auto  w-full ml-16.5 mt-20.5 md:ml-0 lg:mr-0 max-w-8xl"
            src={Frame1640}
            alt="i"
          />
          <img
            onClick={() => {
              setVideoModal(!videoModal);
            }}
            // className="w-full mx-auto ml-6 md:ml-0 lg:mr-0"
            className="absolute top-1/2 left-1/2 cursor-pointer hover:bg-green-400 rounded-full"
            src={PlayButton}
            alt="Play and cancel Video"
          />
        </div>

        {/* FOR THE MOBILE VIEW */}
        <div className=" absolute bottom-14 left-5 flex flex-col md:invisible md:h-0   w-full  ">
          <div
            onClick={() => {
              window.location.href =
                "https://www.clkmg.com/Cashletapp/pswebsite";
            }}
            className=" flex flex-row rounded w-40 justify-between bg-white   shadow-lg overflow-hidden border-black border   text-black font-bold"
          >
            <img className="h-12 w-17  justify-center" src={StoresIcon} />

            {/* 1_0223 */}
            <div className="flex-column self-center pr-1 ">
              <div className=" align-text-bottom my-auto font-medium tracking-tight text-xs">
                Get it on
              </div>
              <div className=" align-text-bottom my-auto tracking-tight text-base">
                Google Play
              </div>
            </div>
          </div>
          <div
            // onClick={() => { setWaitListModal(!WaitListModal) }}
            onClick={() => {
              window.open(
                "https://apps.apple.com/us/app/cashlet-savings-investments/id6463010865",
                "_blank"
              );
            }}
            className="  flex flex-row rounded py-1 w-40 justify-between mt-5  shadow-lg overflow-hidden border-black border   text-black font-bold"
          >
            <img
              className="h-7 w-7 my-auto justify-center mx-auto"
              src={AppStoreIcon}
            />

            {/* 1_0223 */}
            <div className="flex-column self-center pr-5 ">
              <div className=" align-text-bottom my-auto font-medium tracking-tight text-xs">
                Get it on
              </div>
              <div className=" align-text-bottom my-auto tracking-tight text-base">
                App Store
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" text-center text-base text-gray-500 sm:mt-5 sm:mx-auto font-medium font-body md:mt-5  lg:mx-0  mb-2 mt-0 py-2 px-8 md:px-0">
        <span>
          Our Capital Markets Authority (CMA) approved partners in Kenya:
        </span>
      </div>

      <div className="py-3 bg-primary-gray ">
        <div className="md:max-w-7xl mx-auto  px-4 pb-2 pt-2 lg:px-40">
          <div className="items-center flex justify-around flex-wrap space-y-4  ">
            <img src={brand1} alt="Brand1" className="w-24 md:w-44" />
            <img
              src={brand2}
              alt="Brand2"
              className="w-24 orient-partner-image"
            />
            <img
              src={brand3}
              alt="Brand1"
              className="w-24 etica-partner-image"
            />
          </div>
        </div>
      </div>

      <Testimonials />

      <CardFeatures
        className="py-8 "
        textLeft={window.innerWidth < 769}
        title="A radically simple way to save & invest"
        titleClass="md:w-4/6"
        description={[
          "The Cashlet app offers a simple and easy way to save and grow your money. Put your finances in order and plan for your future, all at the same time",
        ]}
        image={Frame1648}
        bgImageVisible={true}
        bgImage={bg1}
        bgClass="hidden lg:block absolute right-0 -top-20 z-100"
        bgClassImg=""
      />
      <CardFeatures
        className="bg-gray-100 py-16 lg:pl-8"
        title="Earn unrivalled interests on your savings"
        textLeft={true}
        description={[
          "Earn 11-16% annual interest on your savings by selecting one of the money market funds on the Cashlet app. These funds are regulated by the Capital Markets Authority of Kenya.",
          "You can track how much interest you are earning on a daily basis",
        ]}
        image={FundManagers}
        bgImageVisible={true}
        bgImage={bg2}
        bgClass="hidden lg:block absolute -left-16 -top-28  z-100"
        bgClassImg="w-40 "
      />
      <CardFeatures
        className="py-8"
        textLeft={window.innerWidth < 769}
        title="Start with little and achieve a lot"
        titleClass="md:w-4/6"
        description={[
          "Kick start your savings with as little as Ksh 100, and watch it grow into something big. You can deposit and withdraw at anytime via M-Pesa",
        ]}
        image={Frame1649}
        bgImageVisible={true}
        bgImage={bg3}
        bgClass="hidden lg:block absolute right-28 -top-32 z-100"
        bgClassImg=""
        bgImage2Visible={true}
        bgImage2={bg1}
        bgClass2="hidden md:block absolute -bottom-24 left-0  z-100"
        bgClassImg2="w-20"
      />
      <CardFeatures
        className="bg-gray-50 py-16 lg:pl-8"
        title="Set and track your progress using Goals"
        textLeft={true}
        description={[
          "Name the goals you want to achieve, set the amount you need to achieve each goal, track your progress, and celebrate each milestone you achieve. You'll be there before you know it!",
          "Saving money doesn’t have to feel like a boring, unattainable chore. Cashlet makes it fun, easy, and interesting. ",
        ]}
        image={Frame1650}
      />
      <CardFeatures
        className="py-8"
        title="Offering you 24/7 support"
        textLeft={window.innerWidth < 769}
        description={[
          "We care about your financial wellbeing and want to help you reach your goals faster. Our support team is here to answer any questions you have via our in-app chat system",
        ]}
        image={Frame1651}
      />

      <Calculator />

      <div className="py-4 md:py-6 bg-gray-100 ">
        <div className="max-w-7xl mx-auto px-5 md:px-8 pb-2 pt-2 lg:px-40">
          <div className="items-center flex justify-around flex-wrap space-y-4">
            <a
              href="https://kenyanwallstreet.com/cma-admit-sycamore-capital-regulatory-sandbox/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={KenyaWallScreet}
                alt="Brand1"
                className="w-36 md:w-44 mt-4"
              />
            </a>

            <a
              href="https://www.businessdailyafrica.com/bd/markets/capital-markets/cma-testing-unit-trust-mobile-app-3685434"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={businessdaily} alt="brnad3" className="w-32 md:w-44" />
            </a>

            <a
              href="https://khusoko.com/2022/01/17/cma-admits-12th-firm-sycamore-capital-into-regulatory-sandbox/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={khusoko} alt="brnad3" className="w-32 md:w-44" />
            </a>

            <a
              href="https://www.kbc.co.ke/cma-admits-sycamore-capital-to-regulatory-sandbox/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={standard} alt="Brand2" className="w-32 md:w-14" />
            </a>
          </div>
        </div>
      </div>

      <Security />

      <Questions />
    </>
  );
};

export default HomePage;
