import React from 'react';
import ReactModal from 'react-modal';
import CloseButton from '../../assets/img/closeButton.png'


export const RegistrationModal = (props) => {
    const { visibility, handleChange } = props;

    const [WaitListModal, setWaitListModal] = React.useState(true)

    const [SubscriberEmail, setSubscriberEmail] = React.useState("")
    const [SubscriberMobile, setSubscriberMobile] = React.useState("")
    const [isloading, setisloading] = React.useState(false)
    const [responseReceive, setresponseReceive] = React.useState("normal")

    // React.useEffect(() => {
    //     console.log("lenth: " + SubscriberMobile.length)
    // }, [SubscriberMobile]) //DEBUG

    const ResponseHandler = (response) => {
        setisloading(false) //REMOVE ACTIVITY INDICATOR

        //CHANGE MODAL CONTENT
        if (response == "success") {
            setresponseReceive("success")
        }
        else if (response == "email_null") {
            setresponseReceive("email_null")
        }
        else if (response == "PhoneFormat") {
            setresponseReceive("PhoneFormat")
        }
        else if (response == "emailFormat") {
            setresponseReceive("emailFormat")
        }
        else {
            setresponseReceive("error")
        }

        //SLIGHT DELAY TO SHOW RESPONSE AND RETURN TO DEFAULT VALUE
        // setTimeout(() => {
        //     // handleChange() //CLOSE MODAL****
        //     // setresponseReceive("normal")
        //     setSubscriberEmail("")
        //     setSubscriberMobile("")
        // }, 1000);
    }

    //RESPONSIBLE FOR DETECTING IF INPUT HAS OCCURED IN EMAIL OR PHONE NUMBER
    // const Submit_SubHandler = () => {
    //     if (SubscriberEmail.length > 1 || SubscriberMobile.length > 1) {
    //         //EITHER EMAIL OR PHONENUMBER HAS BEEN ENTERED 
    //         return false
    //     } else {
    //         //NEITHER EMAIL OR PHONENUMBER HAS BEEN ENTERED
    //         return true
    //     }
    // }

    const InputValidation_Error = () => {
        if (SubscriberEmail.match(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i) != null) {
            console.log("email okay")

            if (SubscriberMobile.length > 1) {
                if (SubscriberMobile.match(/^0\d{9}$/g) != null) {
                    console.log("phone number okay");
                    return false

                }
                else {
                    console.log("wrong phoneNo format");
                    return "PhoneFormat"
                }
            } else {

            }

        } else {
            console.log("error at email");
            return "emailFormat"

        }
    }

    const MailChimpSubmitHandler = () => {
        console.log("email length: " + SubscriberEmail.length)
        setisloading(true)

        const ErrorType = InputValidation_Error()

        if (ErrorType == "PhoneFormat") {
            ResponseHandler("PhoneFormat")
        }
        else if (ErrorType == "emailFormat") {
            ResponseHandler("emailFormat")

        } else {
            let RequestBody
            if (SubscriberMobile.length > 1) {
                let slicedPhoneNo = SubscriberMobile.slice(1)
                RequestBody = {
                    "email": `${SubscriberEmail}`,
                    "phoneNumber": {
                        "countryCode": "+254",
                        "number": `${slicedPhoneNo}`
                    }
                }
            } else {
                RequestBody = {
                    "email": `${SubscriberEmail}`,
                }

            }

            if (SubscriberEmail.length > 2) {
                //list id and authToken move to env list
                //confirm about setting an env file for url prefixes due to migratiing from staging to production
                console.log(JSON.stringify(RequestBody));//DEBUGGING
                fetch("https://cashlet-api-dev.herokuapp.com/api/public/mailing-list", {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(RequestBody)
                })
                    .then((response) => {
                        // console.log("status is " + response.status)
                        if (response.status == 204) {
                            // handleChange()
                            // console.log("passed")
                            //RETURN TO DEFAULT VALUES//CLEANUP//
                            // setisloading(false)
                            ResponseHandler("success")
                        } else {
                            console.log(response.status)
                            ResponseHandler("failure")
                        }
                    }).catch((e) => { console.log(e) })
            }
            else {
                ResponseHandler("email_null")
            }
        }
    }


    function EmailHandler(e) {
        setSubscriberEmail(e.target.value)
        // if (SubscriberEmail.match(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i) != null) {
        //     return true
        // }

    }

    function PhoneNo_Handler(e) {
        setSubscriberMobile(e.target.value)
        // if (e.target.value.match(/^0\d{9}$/g) != null) {
        //     setSubscriberMobile(e.target.value)
        //     console.log("correct moile")
        // }else{ console.log("wrong mobile"); }
    }

    return (
        
        // <ReactModal
        //     shouldCloseOnOverlayClick={true}
        //     shouldCloseOnEsc={true}
        //     shouldReturnFocusAfterClose={true}
        //     preventScroll={false}
        //     shouldCloseOnEsc={true}
        //     // isOpen={WaitListModal}
        //     isOpen={visibility}

        //     ariaHideApp={false}
        //     className=" h-4/6 mt-24 rounded p-6 shadow-lg bg-white  flex w-10/12 lg:w-1/3 mx-auto  flex-col"
        //     style={{
        //         overlay: {
        //             backgroundColor: '#00000070'
        //         }
        //     }}>
<>
<ReactModal
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        preventScroll={false}
        shouldCloseOnEsc={true}
        // isOpen={WaitListModal}
        isOpen={visibility}

        ariaHideApp={false}
        className=" h-4/6 mt-20 rounded p-6 shadow-lg bg-white  flex w-10/12 lg:w-1/3 mx-auto  flex-col"
        style={{
          content: {
            position: 'absolute',
            top: '40px',
            left: '40px',
            right: '40px',
            bottom: '40px',
            border: '1px solid #ccc',
            // background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          },
          overlay: {
            backgroundColor: '#00000070'
          }
        }}>
            <div className="  w-full ">
                <img
                    className="  relative left-10/11 bottom-5 h-10 w-10  hover:bg-green-200 rounded-lg "
                    onClick={() => {
                        setSubscriberEmail("")
                        setSubscriberMobile("")
                        setresponseReceive("normal")
                        handleChange()
                    }}
                    src={CloseButton}
                    alt="Play Video"
                />


                <div className=" text-2xl tracking-tight font-semibold  text-gray-900 text-center mb-3 mt-1">
                    Cashlet Waitlist
                </div>

                <div className="text-center" >
                    We're bringing Cashlet to Android and iOS soon. Add your details below to get notified when it's available for download</div>
            </div>

            <div>

                <form className="flex flex-col flex-shrink-0 pt-2 space-y-4">
                    {/* <div className="grid grid-cols-6 gap-2"> */}
                    <div className="col-span-6 mb-3">
                        <label
                            htmlFor="starting_amount"
                            className="block text-sm md:text-md font-medium text-primary-dark">
                            Email
                        </label>
                        <input
                            id="starting_amount"
                            onChange={(e) => { EmailHandler(e) }}
                            type="text"
                            className="placeholder-gray-400 placeholder-opacity-75 mt-1   p-3  block w-full shadow border border-gray-100 rounded"
                            placeholder="name@mail.com"
                        />
                    </div>

                    {/* <div className=" w-full ">
                        <div className="text-2xl tracking-tight font-normal text-center">
                            or
                        </div>
                    </div> */}

                    <div className="col-span-6">
                        <label
                            htmlFor="contribution"
                            className="block text-sm md:text-md font-medium text-primary-dark"
                        >
                            Mobile number (optional)
                        </label>
                        <input
                            id="contribution"
                            onChange={(e) => { PhoneNo_Handler(e) }}
                            type="text"
                            placeholder="07xxxxxxxx"
                            className="placeholder-gray-400 placeholder-opacity-75 mt-1 p-3  block w-full shadow border border-gray-100 rounded"
                        />
                    </div>
                    {/* </div> */}
                </form>

                {isloading ?
                    <div class=" flex justify-center items-center mt-3">
                        <div class="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary-green"></div>
                    </div>
                    :
                    <div className="py-3">
                        <button
                            // disabled={Submit_SubHandler()}
                            onClick={() => { MailChimpSubmitHandler() }}
                            className="w-full bg-primary-green text-white p-2 rounded-full font-semibold text-lg capitalize mt-3">
                            Join Waitlist
                        </button>
                    </div>
                }
            </div>

            {responseReceive == "success" ?
                <div className="font-bold text-center">
                    {/* Keep an eye on email or sms when we launch. */}
                    You are added! Keep an eye out for an email or sms when we launch.
                </div> :
                responseReceive == "normal" ?
                    null :
                    responseReceive == "error" ?
                        <div className="font-bold text-center">
                            An error occured
                        </div> :
                        responseReceive == "email_null" ?
                            <div className="font-bold text-center">
                                Please fill in an email address
                            </div> :
                            responseReceive == "PhoneFormat" ?
                                <div className="font-bold text-center">
                                    Invalid mobile number. Try again with your correct 10-digit mobile number
                                </div> :
                                responseReceive == "emailFormat" ?
                                    <div className="font-bold text-center">
                                        Invalid email format. Try again with your correct email address
                                    </div> :
                                    null
            }
         </ReactModal>
        
        </>
    )

}
