import HomePage from '../pages/HomePage/HomePage';
import JoinTeamPage from '../pages/JoinTeamPage/JoinTeamPage';
import SecurityPage from '../pages/SecurityPage/SecurityPage';
import AboutPage from '../pages/AboutPage/AboutPage';
import FAQPage from '../pages/FAQPage/FAQPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage/PrivacyPolicyPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage/TermsAndConditionsPage';
import BlogListPage from '../pages/BlogPage/BlogListPage/BlogListPage';
import BlogDescriptionPage from '../pages/BlogPage/BlogDescriptionPage/BlogDescriptionPage';
import IOSLandingPage from '../pages/IOSLandingPage/IOSLandingPage';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    layout: '/main-layout',
  },
  {
    path: '/join-team',
    name: 'Join Team',
    component: JoinTeamPage,
    layout: '/main-layout',
  },
  {
    path: '/security',
    name: 'Security',
    component: SecurityPage,
    layout: '/main-layout',
  },
  {
    path: '/about-us',
    name: 'About',
    component: AboutPage,
    layout: '/main-layout',
  },
  {
    path: '/FAQ',
    name: 'FAQs',
    component: FAQPage,
    layout: '/main-layout',
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicyPage,
    layout: '/main-layout',
  },
  {
    path: '/T&C',
    name: 'Terms And Conditions',
    component: TermsAndConditionsPage,
    layout: '/main-layout',
  },
  {
    path: '/',
    name: 'Blog List',
    component: BlogListPage,
    layout: '/blog',
  },
  {
    path: '/ios-coming-soon',
    name: 'IOS Coming Soon',
    component: IOSLandingPage,
    layout: '/main-layout',
  },
  {
    path: '/:slug',
    name: 'Blog Description',
    component: BlogDescriptionPage,
    bgClass: 'bg-white',
    layout: '/blog',
  },
];
export default routes;
