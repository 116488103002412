import React, { useState } from "react";
import IOSDevice from "../../assets/img/ios/ios-device.png";
import Frame1638 from "../../assets/img/Frame1638.svg";
import FeatureOne from "../../assets/img/ios/ios-feature-one.png";
import FeatureTwo from "../../assets/img/ios/ios-feature-two.png";
import FeatureThree from "../../assets/img/ios/ios-feature-three.png";
import FeatureFour from "../../assets/img/ios/ios-feature-four.png";
import FeatureFive from "../../assets/img/ios/ios-feature-five.png";
import FeatureSix from "../../assets/img/ios/ios-feature-six.png";
import MailIcon from "../../assets/img/mail.png";
import bg3 from "../../assets/img/bg/bg-3.svg";
import Frame1665 from "../../assets/img/Frame1665.svg";
import "./ios.css";
import { Regex } from "../../utils/regex";

function IOSLandingPage() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  const handleChange = (e) => {
    let value = e.target.value;
    setEmail(value);

    if (Regex.email.test(value)) {
      setError(false);
    }
  };

  const handleSubmit = () => {
    if (email && Regex.email.test(email)) {
      setIsLoading(true);
      setIsFailure(false);
      setIsSuccess(false);
      setError(false);

      const payload = {
        email,
        platform: "ios",
      };

      fetch(
        "https://cashlet-api-dev.herokuapp.com/api/public/mailing-list/add_to_both_access_list",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      )
        .then((response) => {
          if (response?.status === 204 || response?.status === 200) {
            setIsLoading(false);
            setIsSuccess(true);
            setTimeout(() => {
              setIsSuccess(false);
              setEmail("");
            }, 4000);
          } else {
            setIsLoading(false);
            setIsFailure(true);
            setTimeout(() => {
              setIsFailure(false);
            }, 4000);
          }
        })
        .catch((error) => {
          // console.log(error);
          setIsLoading(false);
          setIsFailure(true);
          setTimeout(() => {
            setIsFailure(false);
          }, 4000);
        });
    } else {
      setError(true);
    }
  };
  return (
    <div>
      <div className="pt-24 md:pt-40 pb-20 px-10 w-full md:w-9/12 mx-auto">
        <div className="flex flex-col md:flex-row justify-center items-center w-full">
          <div className="absolute left-10 xl:left-2 2xl:left-28 top-36 md:top-20 lg:top-20 xl:top-32  z-100">
            <img
              className="h-40 lg:h-56 xl:h-80 bounce-top-icons"
              src={Frame1638}
              alt="..."
            />
          </div>
          <div className="flex flex-col w-full md:w-3/5">
            <div className="flex flex-col">
              <h1 className="text-2xl tracking-tight font-bold text-gray-900  md:text-3xl lg:text-5xl text-left slide-in-bottom-h1">
                Cashlet is coming
              </h1>
              <h1 className="text-2xl tracking-tight font-bold text-gray-600  md:text-3xl lg:text-5xl text-left slide-in-bottom-h1">
                to IOS
              </h1>
              <p className="text-base text-gray-500 sm:mt-5  xl:max-w-1.75xl sm:mx-auto mt-4 md:mt-5 md:text-lg lg:mx-0 text-left slide-in-bottom-subtitle">
                Dear iPhone users, we're coming soon to iOS in a few weeks time!
                Drop your email below, if you want to get notified when we go
                live
              </p>
            </div>

            <div className="hidden md:block w-10/12 mt-32">
              <div className="flex items-center bg-gray-100 mx-auto rounded-lg">
                <div className="bg-gray-200 rounded-2xl ml-2 px-2 py-1">
                  <img src={MailIcon} className="w-8 h-5" alt="email icon" />
                </div>
                <div className="w-full">
                  <input
                    className="block p-3 pl-4 w-full text-sm focus:ring-0 focus:ring-offset-0 focus:outline-none border-none focus:border-none bg-transparent"
                    placeholder="Enter your email here"
                    type="email"
                    value={email}
                    id="email"
                    name="email"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </div>
                <div>
                  <button
                    style={{ backgroundColor: "#233143", fontSize: "10px" }}
                    onClick={handleSubmit}
                    disabled={isLoading || isSuccess || isFailure}
                    type="button"
                    className="tracking-widest shadow-md py-4 px-10 w-full text-white font-bold border hover:cursor-pointer rounded-xl whitespace-nowrap"
                  >
                    {isLoading ? (
                      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-primary-green"></div>
                    ) : isSuccess ? (
                      <div className="flex">
                        <svg
                          className="h-4 w-4 text-green-500"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          {" "}
                          <path stroke="none" d="M0 0h24v24H0z" />{" "}
                          <circle cx="12" cy="12" r="9" />{" "}
                          <path d="M9 12l2 2l4 -4" />
                        </svg>
                        <span className="ml-2 my-auto text-green-500">
                          Subscribed
                        </span>
                      </div>
                    ) : isFailure ? (
                      <div className="flex">
                        <svg
                          className="h-4 w-4 text-red-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>{" "}
                        <span className="ml-2 my-auto text-red-500">
                          Failed
                        </span>
                      </div>
                    ) : (
                      "NOTIFY ME"
                    )}
                  </button>
                </div>
              </div>
              {error ? (
                <div className="text-red-800 text-xs mt-1">
                  *Please enter a valid email*
                </div>
              ) : null}
              <p className="text-xs text-gray-500">
                Don't worry. We will not spam you.
              </p>
            </div>
          </div>
          <div className="w-full md:w-2/5 flex flex-col md:flex-row justify-center md:justify-end items-center md:items-end">
            <div>
              <img
                className="mb-10 md:mb-5 h-52 md:h-auto md:w-auto"
                src={IOSDevice}
                alt="ios mobile"
              />
            </div>
            <div className="visible md:hidden w-full">
              <div className="flex items-center bg-gray-100 mx-auto rounded-lg">
                <div className="bg-gray-200 rounded-2xl ml-2 px-2 py-1">
                  <img src={MailIcon} className="w-8 h-5" alt="email icon" />
                </div>
                <div className="w-full">
                  <input
                    className="block p-3 pl-4 w-full text-sm focus:ring-0 focus:ring-offset-0 focus:outline-none border-none focus:border-none bg-transparent"
                    placeholder="Enter your email here"
                    type="email"
                    value={email}
                    id="email"
                    name="email"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </div>
                <div>
                  <button
                    style={{ backgroundColor: "#233143", fontSize: "10px" }}
                    onClick={handleSubmit}
                    disabled={isLoading || isSuccess || isFailure}
                    type="button"
                    className="tracking-widest shadow-md py-4 px-6 w-full text-white font-bold border hover:cursor-pointer rounded-xl whitespace-nowrap"
                  >
                    {isLoading ? (
                      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-primary-green"></div>
                    ) : isSuccess ? (
                      <div className="flex">
                        <svg
                          className="h-4 w-4 text-green-500"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          {" "}
                          <path stroke="none" d="M0 0h24v24H0z" />{" "}
                          <circle cx="12" cy="12" r="9" />{" "}
                          <path d="M9 12l2 2l4 -4" />
                        </svg>
                        <span className="ml-2 my-auto text-green-500">
                          Subscribed
                        </span>
                      </div>
                    ) : isFailure ? (
                      <div className="flex">
                        <svg
                          className="h-4 w-4 text-red-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>{" "}
                        <span className="ml-2 my-auto text-red-500">
                          Failed
                        </span>
                      </div>
                    ) : (
                      "NOTIFY ME"
                    )}
                  </button>
                </div>
              </div>
              {error ? (
                <div className="text-red-800 text-xs mt-1">
                  *Please enter a valid email*
                </div>
              ) : null}
              <p className="text-xs text-gray-500">
                Don't worry. We will not spam you.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="absolute left-24 pt-10 xl:left-28 z-100">
          <img className="" src={bg3} alt="..." />
        </div>
        <div className="absolute right-24 mt-56 md:mt-20 z-100">
          <img className="" src={Frame1638} alt="..." />
        </div>
        <div className="w-full md:w-9/12 mx-auto px-10 py-14">
          <div className="text-lg tracking-tight font-bold text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
            Cool Features to Look Out For
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-6 py-10">
            <div className="flex justify-center md:justify-start">
              <div className="flex flex-col items-center">
                <div className="bg-gray-200 rounded-full p-2">
                  <img className="w-10 h-10" src={FeatureOne} alt="feature" />
                </div>
                <div className="text-sm text-gray-500 mt-2">
                  #1 Earn impressive
                </div>
                <div className="text-sm text-gray-500">
                  interest rates of 9-11%
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="flex flex-col items-center">
                <div className="bg-gray-200 rounded-full p-2">
                  <img className="w-10 h-10" src={FeatureTwo} alt="feature" />
                </div>
                <div className="text-sm text-gray-500 mt-2">
                  Start with as little as
                </div>
                <div className="text-sm text-gray-500">KES 500</div>
              </div>
            </div>
            <div className="flex justify-center md:justify-end md:mr-3">
              <div className="flex flex-col items-center">
                <div className="bg-gray-200 rounded-full p-2">
                  <img className="w-10 h-10" src={FeatureThree} alt="feature" />
                </div>
                <div className="text-sm text-gray-500 mt-2">
                  It's Lightning fast
                </div>
              </div>
            </div>
            <div className="flex justify-center md:justify-start">
              <div className="flex flex-col items-center">
                <div className="bg-gray-200 rounded-full p-2">
                  <img className="w-10 h-10" src={FeatureFour} alt="feature" />
                </div>
                <div className="text-sm text-gray-500 mt-2">
                  Choose from several
                </div>
                <div className="text-sm text-gray-500">money market funds</div>
                <div className="text-sm text-gray-500">in Kenya</div>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="flex flex-col items-center">
                <div className="bg-gray-200 rounded-full p-2">
                  <img className="w-10 h-10" src={FeatureFive} alt="feature" />
                </div>
                <div className="text-sm text-gray-500 mt-2">
                  Set as many goals as
                </div>
                <div className="text-sm text-gray-500">you like</div>
              </div>
            </div>
            <div className="flex justify-center md:justify-end">
              <div className="flex flex-col items-center">
                <div className="bg-gray-200 rounded-full p-2">
                  <img className="w-10 h-10" src={FeatureSix} alt="feature" />
                </div>
                <div className="text-sm text-gray-500 mt-2">
                  Enjoy security of your
                </div>
                <div className="text-sm text-gray-500">funds being CMA</div>
                <div className="text-sm text-gray-500">regulated</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="absolute pt-2 left-1/2 transform -translate-x-1/2  z-100">
          <img className="" src={Frame1665} alt="..." />
        </div>
        <div className="flex justify-center w-full">
          <div className="flex flex-col items-center py-20">
            <div className="text-md font-bold text-gray-900">
              Cashlet is just getting started
            </div>
            <div className="text-md font-bold text-gray-900">
              and we are coming to save the world
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IOSLandingPage;
