/*
 * Home page that is view if the user is authenticated of not.
 * pages include: Landing page, about page, terms & conditions page (etc.)
 * */

import React, { useEffect, useRef } from "react";
import { useLocation, Redirect, Route, Switch } from "react-router-dom";
import Banner from "../components/Banner/Banner";
import Footer from "../components/Footer/Footer";
import IndexNavbar from "../components/Navbars/IndexNavbar";
import routes from "../routes/routes";

const MainLayout = () => {
  const mainContent = useRef(null);
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add(
      "font-body",
      "tracking-normal",
      "text-primary-darkest"
    );
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.remove(
        "leading-normal",
        "tracking-normal",
        "text-gray-900"
      );
    };
  });

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  function getRoutes(routes) {
    return routes.map((prop, key) => {
      if (prop.layout === "/main-layout") {
        return (
          <Route exact path={prop.path} component={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  }

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <IndexNavbar fixed />
        {/* Page content */}
        <Switch>
          {getRoutes(routes)}
          <Redirect from="/" to="/" />
        </Switch>

        {location.pathname !== "/ios-coming-soon" ? <Banner /> : null}
        <Footer />
      </div>
    </>
  );
};
export default MainLayout;
