import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import Sidebar from '../Sidebar/Sidebar';

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between pl-4 py-1 md:py-3 navbar-expand-lg bg-white border-b-2 border-primary-green border-opacity-20">
        <div className="container  md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl px-0 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <NavLink
              to="/"
              className="text-gray-800 font-body leading-relaxed inline-flex items-center mr-4 py-2 whitespace-no-wrap space-x-4"
            >
              <img className="h-8 w-auto sm:h-10" src={logo} alt="Logo" />
              <span className="font-body text-primary-dark text-2xl md:text-3xl font-bold">Cashlet</span>
            </NavLink>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              {navbarOpen ? (
                <svg
                  className="h-10 w-10  "
                  fill="none"
                  // viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path transform="translate(-0, -0)" class="close-x" strokeWidth="2" d="M 10,10 L 30,30 M 30,10 L 10,30" />
                </svg>
              ) : (
                <svg
                  className="h-10 w-10 "
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              )}



            </button>
          </div>
          <div className="lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none hidden ">
            <ul className="flex flex-col lg:flex-row list-none ml-auto">
              <li className="flex items-center">
                <NavLink
                  exact
                  activeClassName="font-bold border-b-2 border-primary-green"
                  className="hover:text-gray-600 text-black px-3 lg:px-6 py-4 lg:py-2 flex items-center text-center text-md lg:text-md  font-medium"
                  to="/"
                  activeClassName="lg:text-md font-bold border-b-2 border-primary-green "
                  exact
                >
                  Home
                </NavLink>
              </li>
              <li className="flex items-center">
                <NavLink
                  activeClassName="font-bold border-b-2 border-primary-green"
                  className="hover:text-gray-600 text-black px-3 lg:px-6 py-4 lg:py-2 flex items-center text-center text-md lg:text-sm  font-medium"
                  to="/about-us"
                  activeClassName="lg:text-md font-bold border-b-2 border-primary-green "
                  exact
                >
                  About us
                </NavLink>
              </li>
              <li className="flex items-center">
                <NavLink
                  activeClassName="font-bold border-b-2 border-primary-green"
                  className="hover:text-gray-600 text-black px-3 lg:px-6 py-4 lg:py-2 flex items-center text-center text-md lg:text-sm  font-medium"
                  to="/FAQ"
                  activeClassName="lg:text-md font-bold border-b-2 border-primary-green "
                  exact
                >
                  FAQ
                </NavLink>
              </li>
              <li className="flex items-center">
                <NavLink
                  activeClassName="font-bold border-b-2 border-primary-green"
                  className="hover:text-gray-600 text-black px-3 lg:px-6 py-4 lg:py-2 flex items-center text-center text-md lg:text-sm  font-medium"
                  to="/security"
                  activeClassName="lg:text-md font-bold border-b-2 border-primary-green "
                  exact
                >
                  Security
                </NavLink>
              </li>
              <li className="flex items-center">
                <NavLink
                  activeClassName="font-bold border-b-2 border-primary-green"
                  className="hover:text-gray-600 text-black px-3 lg:px-6 py-4 lg:py-2 flex items-center text-center text-md lg:text-sm  font-medium"
                  to="/blog"
                >
                  Blog
                </NavLink>
              </li>
            </ul>
          </div>
          <Sidebar setNavbarOpen={setNavbarOpen} navbarOpen={navbarOpen} />
        </div>
      </nav>
    </>
  );
}
