import React, { useEffect, useState } from 'react';
// import './App.css';
import classNames from 'classnames';

function TimeSection({ className, title, description, year, month, textLeft = true, textRight = false, isMobile }) {
  return (
    <div className={classNames('mb-8 flex justify-between items-start w-full ', className)}>
      <div
        className={classNames('order-1', {
          'text-left': !textLeft,
          'text-right': !textRight,
        })}
        style={ isMobile ? {} : { width:"39.5%"}}
      >
        { isMobile ? null : <h3 className="mt-5 font-bold text-primary-green text-xl">{month}</h3> }
      </div>
      <div style={ isMobile ? {} : { marginRight: "-1.25rem", marginLeft: "-1.25rem"}} className={`${ isMobile ? "order-1 w-2 bg-red-100 ":"order-1 bg-red-100 " }`}></div>
      <div className="z-20 order-1 flex items-start  border-2 border-primary-green bg-white w-auto py-4 px-2 rounded-full">
        <h1 className="mx-auto font-semibold text-lg text-primary-green">{year}</h1>
      </div>
      <div
        className={classNames('order-1 bg-white w-full md:w-5/12 px-6 py-4 ', {
          'text-left': textLeft,
          'text-right': textRight,
        })}
      >
        { isMobile ? <h3 className="-mt-4 font-bold text-primary-green text-xl">{month}</h3> : null }
        <h3 className="mb-3 font-bold text-gray-800 text-xl">{title}</h3>
        <p className="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">{description}</p>
      </div>
    </div>
  );
}

function Timeline() {
  const initialState = {
    isMobile: window.innerWidth < 767,
  };
  const [state, updateState] = useState(initialState);
  const { isMobile } = state;
  function setState(state, value) {
    updateState((prevState) => ({ ...prevState, [state]: value }));
  }
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const screenWidth = window.innerWidth < 767;
        if (screenWidth !== isMobile) setState("isMobile", screenWidth);
      },
      false
    );
  }, [isMobile]);

  return (
    <div className="container  mx-auto w-full h-full pb-10">
      <div className="lg:text-left">
        <h2 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 mb-9">
          Our History
        </h2>
      </div>
      <div
        className={`${
          isMobile ? "p-0" : "p-10"
        } relative wrap overflow-hidden h-full`}
      >
        {/* <div className="border-2-2 absolute border-opacity-20 border-gray-700 h-full border left-1/2"></div> */}

        {/* <div  className={`${ isMobile ? "right-9" : "right-1/2"} border-r-2 border-primary-green mt-10 absolute h-3/4 top-0`}></div> */}
        <div
          style={{ height: "81%" }}
          className={`${
            isMobile ? "right-9" : "right-1/2"
          } border-r-2 border-primary-green mt-10 absolute top-0`}
        ></div>
        {/* <!-- right timeline --> */}
        <TimeSection
          month="April"
          className={`${
            isMobile ? "flex-row-reverse right-timeline" : "right-timeline"
          }`}
          year="2019"
          title="Founded in 2019"
          description="Cashlet was founded in Nairobi, Kenya, with a strong entrepreneurial spirit, and a missing to help everyone to save, invest, and manage their money better."
          isMobile={isMobile}
        />

        {/* <!-- left timeline --> */}
        <TimeSection
          month="January"
          className=" flex-row-reverse right-timeline "
          textRight={isMobile ? false : true}
          year="2020"
          title="Applies for CMA approval"
          description="Cashlet submits application to the Kenya Capital Markets Authority (CMA) - the government body responsible for regulating companies involved in the capital market in Kenya"
          isMobile={isMobile}
        />

        {/* <!-- right timeline --> */}
        <TimeSection
          month="April"
          className={`${
            isMobile ? "flex-row-reverse right-timeline" : "right-timeline"
          }`}
          year="2020"
          title="Secures Partnership agreements"
          description="Cashlet penned a partnership agreement with Genghis Capital. Also commenced discussions with other leading fund managers in Kenya such as UAP Old Mutual and ICEA Lion."
          isMobile={isMobile}
        />

        {/* <!-- left timeline --> */}
        <TimeSection
          month="July"
          className=" flex-row-reverse right-timeline"
          textRight={isMobile ? false : true}
          year="2021"
          title="CMA Sandbox approval"
          description="Obtained approval from the CMA to the join the regulatory Sandbox for Innovative Fintechs."
          isMobile={isMobile}
        />
        <TimeSection
          month="January"
          className={`${
            isMobile ? "flex-row-reverse right-timeline" : "right-timeline"
          }`}
          year="2024"
          title="CMA Sandbox Exit approval"
          description="Successfully exited from the CMA Sandbox and transitioned into an intermediary service platform provider."
          isMobile={isMobile}
        />
      </div>
    </div>
  );
}

export default Timeline;