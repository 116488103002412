import React, { useEffect } from 'react';
import { Link } from "react-scroll";
import ReactGA from 'react-ga'


function SectionItem({ children, id, title }) {
  return (
    <div id={id}>
      {/* <!--Title--> */}
      <h2 className="font-sans font-bold break-normal text-gray-900 py-2 pb-8 text-5xl">{title}</h2>

      {/* <!--Card--> */}
      {children}
      {/* <!--/Card--> */}

      {/* <!--divider--> */}
      <hr className="bg-gray-200 my-12 py-px opacity-25" />
    </div>
  );
}

function TermsAndConditionsPage() {

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  const focusDiv = (ele) => {
    let offsetTop = document.getElementById(ele).offsetTop;

    if (offsetTop) {
      window.scrollTo({
        top: offsetTop - 100,
        behavior: 'smooth',
      });
    }
  };

  return (
    <section className="relative py-0 md:py-16">
      <div className="max-w-6xl md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl  mx-auto">
        {/* -------------------------------------------------- */}
        <div className="container w-full flex flex-wrap mx-auto px-4 md:px-2 pt-0 lg:pt-16 mt-16">
          {/* -------------------------------------------------- */}
          <div className="w-full lg:w-2/6 px-6 text-xl text-gray-800 leading-normal">
            <div
              className="w-full sticky inset-0 hidden max-h-full lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-2 lg:my-0 border border-gray-400 lg:border-transparent bg-white shadow lg:shadow-none lg:bg-transparent z-20"
              style={{ top: '6em' }}
              id="menu-content"
            >
              <h3 className="text-base font-bold text-gray-700">Terms & Conditions</h3>
              <h1 className="text-2xl font-bold py-2 lg:pb-8 text-gray-700">Table of Contents</h1>
              <ul className="list-reset py-2 md:py-0">
                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section1"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">General Terms</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section2"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Terms of Website Use</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section3"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Terms and Conditions for Investing</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section4"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Terms and conditions of CMA Regulatory Sandbox</span>
                </Link>

              </ul>
            </div>
          </div>
          {/* -------------------------------------------------- */}
          <section className="w-full lg:w-4/6">
            <SectionItem id="#section1" title="General Terms">
              <div className=" space-y-8 ">
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    About Cashlet
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Cashlet is a mobile app developed and offered by Sycamore Capital Kenya Limited (herein known as
                    “Sycamore”). Sycamore Is a Kenya registered Company. Our registered office is on 4th Floor, Laiboni Center, Lenana Road, Kilimani,
                     Nairobi, Kenya (Company registration no. PVT-EYUMZ7M). We operate the
                    website{' '}
                    <a className="text-primary-green underline font-semibold" href="/">
                      www.cashlet.co.ke
                    </a>{' '}
                    and the Services available through our App.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Sycamore is part of the Regulatory Sandbox of the Capital Markets Authority (CMA) of Kenya for
                    innovative Fintechs who are launching capital market solutions. Their address is Embankment Plaza,
                    3rd Floor, Longonot Road, off Kilimanjaro Avenue, Upperhill, Nairobi.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    These are the legal terms (Terms and Conditions or Document) between Cashlet (‘we, ‘our’, ‘us’) and
                    you, the person accessing the Cashlet website located at{' '}
                    <a className="text-primary-green underline font-semibold" href="/">
                      www.cashlet.co.ke
                    </a>{' '}
                    (the Site) and/or downloading our mobile app and/or registering for our Services (‘you’’ or
                    ‘’your’’) (the Customer) and Cashlet. You accept them during your registration and they will be
                    legally binding when you use the Cashlet app.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    This Document contains important information regarding the services that we provide to you so for
                    your own benefit and protection, please read it carefully before accepting it. You should read this
                    document along with our{' '}
                    <a className="text-primary-green underline font-semibold" href="/privacy-policy">
                      Privacy Policy
                    </a>
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If you wish to contact us for any reason, including because you have any complaints, you can contact
                    us by emailing us at{' '}
                    <a className="text-primary-green underline font-semibold"
                      href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}
                    </a>{' '}

                    We can also be contacted in-app via our chat widget.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If we have to contact you or give you notice in writing, we will do so by email, through the site or
                    App or through an instant messaging service using details you provide to us. We may also contact you
                    by telephone or SMS. It is your responsibility to ensure the contact information we have for you is
                    up to date. You are required to notify us promptly if there is a change to: your name; your address;
                    your Personal Accounts; your mobile number and/or your status as a Kenya resident. Following any of
                    these changes It might be necessary for us to ask you for, and for you to provide, more information
                    as part of this process.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    With your consent we will send you notifications on your transactions, accounts and in-app news on
                    new features and products being introduced.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Our Customers and Services
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You must be over 18 years old and a Kenya resident to use Cashlet. In light of this you warrant and
                    represent to us that you are aged 18 years or over and are resident in Kenya.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You agree to notify us immediately on an ongoing basis if your status changes.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We are required to verify your identity in accordance with Kenya regulations on investor protection
                    and functioning of financial markets and we do this as part of opening a Cashlet account. The ‘know
                    your customer process’ (KYC) is a law requirement and if you do not provide the necessary
                    documentation you will not be able to complete your onboarding process.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We treat Customers that are considered politically exposed persons (PEPs) with particular care and
                    attention. A PEP refers to an individual – whether foreign or domestic – with a prominent public
                    function, as well as his or her family members and close personal or business associates.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We require you to inform us during the onboarding process or immediately after, whether you are or
                    became a PEP.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Prior to the launch of features of our app, we may provide an opportunity for visitors to the Site
                    to enter their email to join a wait list of visitors who wish to receive further information as such
                    feature gets under way (a “Wait List”). If you join any Wait List, you acknowledge and agree that
                    joining such Wait List does not and will not make you our client, obligate us to provide you any
                    services, or create any other legal or regulatory duty on our part, other than the duty to exercise
                    reasonable care to protect your contact details in accordance with our{' '}
                    <a className="text-primary-green underline font-semibold" href="/privacy-policy">
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Your Cashlet Account
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    The Cashlet Account is your account with us, which we will activate for you through the mobile application (the App),
                    when you complete the onboarding process. You will receive a notification that your Cashlet Account is active.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You are the only owner of your Cashlet Account, which is personal, non-transferable.
                    You are not allowed to use it for business or commercial purposes. You cannot open more than one Cashlet Account.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Your Cashlet Account will allow you to save and invest money. For the purpose of this document these
                    are called Cashlet Services.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Cashlet does not hold Customer’s money. When you make a deposit, the funds move directly into the collection
                    bank account of our partner fund managers such as ICEA Lion Asset Management. These are all established fund managers
                    who are authorized and regulated by the Capital Markets Authority (CMA) of Kenya.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Application for Services
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    In order to receive the Services, you must complete an application ("Application"). By completing
                    our Application, you confirm that the information you have provided is accurate and complete.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If any information provided in your Application is found to be incorrect or incomplete and we have
                    not received the outstanding information within 10 Business Days of receipt of your Application we
                    may reject your Application and return to you any cash received.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If we have been unable to complete identity verification checks to our satisfaction within 10
                    Business Days of receipt of your Application (regardless of the method of payment), we may reject
                    your Application and return any monies received from you.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We have full discretion to accept or not to accept an Application and we reserve the right to reject
                    your Application and not to give you any reasons for doing so.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Access to Cashlet
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Access to the Services will be through our App. You must not allow anyone to access the Services on
                    your behalf. You must treat as confidential; the user identification code, 4-digit passcode, and any
                    other piece of information as part of a security procedures, and you must not disclose it to any
                    third party.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Your right to close your account
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You may close your Cashlet Account, subject to the settlement of all outstanding transactions in
                    your Cashlet Account, at any time and for any reason by providing us with a written notice at{' '}
                    <a className="text-primary-green underline font-semibold"
                      href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}
                    </a>
                    . Transactions already in progress will be completed in the normal course of business.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If you have investments open, upon your instruction, we will request through the Mandate that you
                    have granted us (Section 3.6), our partner fund managers to close them for you and once the money
                    has been safely disinvested and received in your Cashlet Account, we can close it. When we refund
                    your money it will be an updated value of your investment according to the performance of the
                    product at that moment, net of the fees and charges applied on the investment product. The money so
                    calculated will then be returned to your Cashlet Account.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Our right to suspend or close the Cashlet Account
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We can immediately freeze your Cashlet Account without notice if:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <li>you contact us to inform us that your mobile phone has been lost or stolen</li>
                    <li>
                      you haven't given us any information we need, or we have good reason to believe that information
                      you have provided is incorrect or not true
                    </li>
                    <li>we have good suspects that you are behaving fraudulently</li>
                    <li>
                      you've seriously and persistently broken the terms and conditions contained in this document
                    </li>
                    <li>
                      we believe that your use of the App can cause a damage to us or our software, systems or hardware
                    </li>
                    <li>
                      we have good reason to believe that you continuing to use your Cashlet account could damage our
                      reputation or goodwill
                    </li>
                    <li>we have to do so under any law, regulation, court order or ombudsman’s instructions</li>
                    <li>
                      for technical problems, emergencies, maintenance, software implementation or and if this happens
                      we will do our best to contact you to let you know what you should do
                    </li>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We may also decide to close your Cashlet Account due to the above reasons if the above mentioned
                    cases are particularly concerning for Cashlet. Should we decide to close your Cashlet Account we
                    will contact you through the App at least 14 business days before we do so.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If you have on-going investments we will freeze your investments and wait for your further
                    instructions. If we do not receive your instructions within 14 business days from the communication,
                    Cashlet will proceed to disinvest your money through the Mandate that you have assigned us (Section
                    3.6). When we refund your money it will be an updated value of your investment according to the
                    performance of the product at that moment, net of the fees and charges applied on the investment
                    product. The money so calculated will be returned to your Cashlet Account. We will proceed sending
                    the money to your M-Pesa account.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Complaints
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Should you have any complaints in relation to the services provided by us then please notify us by email at:{' '}
                    <a className="text-primary-green underline font-semibold"
                      href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}
                    </a>{' '}
                    or through in-app chat system. We will acknowledge your complaint promptly and arrange for the matter to be investigated in accordance with the
                    complaint handling rules of the Capital Markets Authority (CMA) and report the results to you with a final response.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If you remain dissatisfied with our response, we will always inform you that you may refer the complaint to the Capital Markets Authority (CMA) of Kenya.
                    The address of the CMA is Embankment Plaza, 3rd Floor, Longonot Road, off Kilimanjaro Avenue, Upperhill, Nairobi. You can also telephone the CMA at: 254 722 207767.
                    You can find out further information about the CMA on its website: www.cma.or.ke.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Fees
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We reserve a right to charge a fee for the Services we offer, as set out on the site from time to time. For more information on our current Fee Schedule see our website or App.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Third Party Providers (TPP)
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    To provide the Services to you we partner with a number of third parties.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    They are:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <li>the entities that are authorized and regulated by the CMA who make saving and investing your money to earn interest possible;</li>
                    <li>the entities that  enable mobile money transfers so that you can deposit into and withdraw from your Cashlet account;</li>
                    <li>the entities that help us to verify your identity in accordance with know your customer (KYC) anti-money laundering legislation;</li>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Please refer to our Privacy Policy for information about our partners’ and suppliers’ processing of personal data.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We may from time to time, appoint additional or alternative partners and suppliers to provide the Services to you or services to us.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Interruption of the Services
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Cashlet aims to ensure that the App and your Cashlet Account are available and up and running for you to use at any time however.
                    However there may be times where the App or the Cashlet Account may not be available and where we may not be able to facilitate your investment instructions.
                    There may be interruptions and/or delays to our Cashlet Services which do not depend on us. If this happens we will do our best to contact you to let you know
                    what you should do. If you need you can also contact us by email at:{' '}
                    <a className="text-primary-green underline font-semibold"
                      href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}
                    </a>.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Warranties
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You expressly understand and agree that:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <li>Your use of the Services and all information, products and other content (including that of third parties) included in or accessible from us is at your sole risk.</li>
                    <li>
                      the Services are provided on an "as is" and "as available" basis. We and our Partners expressly disclaim all warranties of any kind as to us and all information,
                      products and other content (including that of third parties) included in or accessible from us, whether express or implied, including, but not limited to the implied
                      warranties of merchantability, fitness for a particular purpose and non-infringement.
                    </li>
                    <li>the entities that help us to verify your identity in accordance with know your customer (KYC) anti-money laundering legislation;</li>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Neither we nor our Aggregation Partner make any warranty that:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <li>we will meet your requirements;</li>
                    <li>we will be uninterrupted, timely, secure, or error-free;</li>
                    <li>the results that may be obtained from the use of the Services will be accurate or reliable;</li>
                    <li>the quality of the Services or any products, services, information, or other amenities or material purchased or obtained by you through us will meet your expectations; or</li>
                    <li>any errors in the technology will be corrected.</li>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Any material downloaded or otherwise obtained through the use of the Services is done at your own discretion and risk and you are solely
                    responsible for any damage to your computer system or loss of data that results from the download of any such material. No advice or information,
                    whether oral or written, obtained by you from our Partners or through or from us will create any warranty not expressly stated in these Terms.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You agree to protect and fully compensate our Partners and us from any and all third party claims, liability, damages, expenses and costs (including, but not limited to,
                    reasonable solicitors’ fees) caused by or arising from your use of the Services, your violation of these Terms or your infringement, or infringement by any other user of your
                    Cashlet Account, of any intellectual property or other right of anyone.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Our liability
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If we fail to comply with these Terms, we are responsible for loss or damage you suffer that is a foreseeable result of our breach of these Terms or our negligence,
                    but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if it is an obvious consequence of our breach or if it
                    was contemplated by you and us at the time you started using the Services
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If our App damages a device or digital content belonging to you, we will either repair the damage or pay you compensation.
                    However, we will not be liable for damage that you could have avoided by following our advice to apply an update offered to you free of charge.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We only provide the Services for domestic and private use. You agree not to use the Services for any commercial, business or resale purposes,
                    and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Changes to Cashlet Terms and Conditions
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We expect to update or amend these Terms and Conditions from time to time to meet our changing business requirements.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We may also amend them:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <li>to make these terms fairer to you or easier to understand, or to correct mistakes;</li>
                    <li>to reflect changes in market practice or conditions;</li>
                    <li>to reflect changes imposed by laws or regulations;</li>
                    <li>to reflect changes to our arrangements with any of our service providers.</li>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    The above changes will be enforced immediately. We will notify you about the change within 30 days of the change.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If we make a change to these Terms that may have a relevant impact on you, we will provide you with at least 30 days’ prior notice.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Right of disposal
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Upon the death of the owner, the Cashlet Account will be closed.  We will require the person who approaches Cashlet claiming to be the owner’s
                    legal successor to provide suitable proof according to the inheritance law to Cashlet, a valid ID and a death certificate to us. In case the owner has investments ongoing,
                    we will request our partners fund managers to close them under the Mandate that we have been given (Section 3 para 6) and once the money has been safely disinvested and
                    received in the Cashlet Account, we can then close the Cashlet Account. When we refund the money it will be an updated value of the investment according to the performance
                    of the product at that moment, net of the fees and charges applied on the investment product.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Law Applicable
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    These Terms and Conditions and any dispute or claim arising out of or in connection with them (including non-contractual disputes or claims) will be governed by and construed
                    in accordance with the laws of Kenya.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    The parties irrevocably agree that the courts of Kenya will have exclusive jurisdiction to settle any dispute or claim that arises out of or in
                    connection with these Terms and Conditions (including non-contractual disputes or claims).
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    All disputes are subject, firstly, to mediation, and then by Arbitration by a sole arbitrator appointed by Cashlet. The venue of arbitration will be Nairobi, Kenya.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Intellectual property
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Our App and website and all intellectual property rights contained therein, are owned by Cashlet or Cashlet’s licensors. Intellectual property rights means rights such as,
                    but not limited to: copyright, trademarks, domain names, design rights, database rights, patents and all other intellectual property rights of any kind whether or not
                    they are registered or unregistered anywhere in the world. In addition, all page headers, custom graphics, button icons or the like are owned by Cashlet or Cashlet’s licensors.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You may not download, reproduce, transmit or modify any of them without our prior written permission.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    All content is protected by applicable Kenya and international intellectual property laws and treaties.
                  </p>
                </div>{' '}
              </div>
            </SectionItem>

            <SectionItem id="#section2" title="Terms of Website Use">
              <div className=" space-y-8 ">
                <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                  This terms of use (together with the documents referred to in it) tells you the terms of use on which you may make use of our website www.cashlet.co.ke,
                  whether as a guest or a registered user. Use of Cashlet includes accessing, browsing, or registering to use Cashlet. Please read these terms of use carefully before
                  you start to use Our Site, as these will apply to your use of Our Site. We recommend that you print a copy of this for future reference. By using Cashlet,
                  you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms of use, you must not use Cashlet.
                </p>
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Other applicable terms
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    These terms of use refer to the following additional terms, which also apply to your use of our site:
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Our Privacy Policy, which can be found through Cashlet, sets out the terms on which we process any personal data we collect from you, or that you provide to us.
                    By using our site, you consent to such processing and you warrant that all data provided by you is accurate.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Our Cashlet Terms & Conditions which can be found through Cashlet, apply to users who sign up to use our services (“Cashlet Terms and Conditions”).
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    The terms and conditions applying to any Product.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Information about us
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    www.cashlet.co.ke is a site that is operated for the benefit of the Cashlet app, a product from Sycamore Capital ("We"). We are registered in Kenya,
                    under company number PVT-EYUMZ7M and have our registered office on 4th Floor, Laiboni Center, Lenana Road, Kilimani, Nairobi.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Changes to these terms
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We may revise these terms of use at any time by amending this page. Please check this page from time to time to take notice of any changes we made, as they are binding on you.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Changes to our Site
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We may update our Site from time to time, and may change the content at any time. However, please note that any of the content on our Site may be out of date at any given time,
                    and we are under no obligation to update it. If we change these terms of use, we will post the revised document here with an updated effective date. If we make significant
                    changes to these terms of use, we may also notify you by other means such as sending an email, messaging you via an instant messaging service or posting a notice on our home page.
                    We do not guarantee that our site, or any content on it, will be free from errors or omissions.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Accessing our Site
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Our site is made available free of charge. We do not guarantee that our Site, or any content on it, will always be available or be uninterrupted.
                    Access to our Site is permitted on a temporary basis. We may suspend, withdraw, discontinue or change all or any part of our Site without notice.
                    We will not be liable to you if for any reason our Site is unavailable at any time or for any period. We reserve the right to restrict or deny you access to
                    all or some parts of our Site if, in our opinion, you have failed to comply with these terms of use. You are responsible for making all arrangements necessary
                    for you to have access to our Site. You are also responsible for ensuring that all persons who access our Site through your internet connection are aware of
                    these terms of use and other applicable terms and conditions, and that they comply with them. Our Sit is directed to people residing in Kenya We do not represent
                    that content available on or through our Site is appropriate or available in other locations. We may limit the availability of our Site or any service or product described
                    on Cashlet to any person or geographic area at any time. If you choose to access our Site from outside the Kenya, you do so at your own risk.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Intellectual property rights
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We are the owner or the licensee of all intellectual property rights in relation to our Site, and in the material published on it. Those works are protected by copyright
                    laws and treaties around the world. All such rights are reserved. You may print off one copy, and may download extracts, of any page(s) from our Site for your personal
                    use and you may draw the attention of others within your organization to content posted on our site. You must not modify the paper or digital copies of any materials you
                    have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
                    Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged. You must not use any part of the content on our Site
                    for commercial purposes without obtaining a licence to do so from us or our licensors. If you print off, copy or download any part of our Site in breach of these terms of use,
                    your right to use Cashlet will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
                  </p>
                </div>{' '}

                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Limitations of our liability
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Nothing in these terms of use excludes or limits our liability for death or personal injury arising from our negligence, or our fraud or fraudulent misrepresentation,
                    or any other liability that cannot be excluded or limited by Kenyan law. To the extent permitted by law, we exclude all conditions, warranties, representations or other
                    terms which may apply to our Site or any content on it, whether express or implied. We will not be liable to any user for any loss or damage, whether in contract,
                    tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <li>use of, or inability to use, Cashlet; or</li>
                    <li>use of or reliance on any content displayed on our site.</li>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    As a consumer using Cashlet, please note that we only provide Cashlet for domestic and private use. You agree not to use Cashlet for any commercial or business purposes,
                    and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity. We will not be liable for any loss or
                    damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programs,
                    data or other proprietary material due to your use of our Site or to your downloading of any content on it, or on any website linked to it. We assume no responsibility for the content of
                    websites linked to from Cashlet. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from
                    your use of them. Different limitations and exclusions of liability will apply to liability arising as a result of the supply of any services to you, which will be set out in the
                    Cashlet Terms and Conditions.
                  </p>
                </div>{' '}

                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Viruses
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We do not guarantee that Cashlet will be secure or free from bugs or viruses. You are responsible for configuring your information technology,
                    computer programmes and platform in order to access our Site. You should use your own virus protection software. You must not misuse our site by
                    knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to
                    gain unauthorized access to our site, the server on which our site is stored or any server, computer or database connected to our Site. You must not
                    attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence
                    under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities
                    by disclosing your identity to them. In the event of such a breach, your right to use Cashlet will cease immediately.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Linking to Cashlet
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.
                    You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.
                    You must not establish a link to Cashlet in any website that is not owned by you. Cashlet must not be framed on any other site, nor may you create
                    a link to any part of our site other than the home page. We reserve the right to withdraw linking permission without notice. If you wish to make any
                    use of content on Cashlet other than that set out above, please contact{' '}
                    <a className="text-primary-green underline font-semibold"
                      href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}
                    </a>.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Validity
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If any part or provision of these terms of use are found to be unlawful or unenforceable, this shall not affect the validity of any other part or provision
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Third party links and resources on our Site
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Where Cashlet contains links to other sites and resources provided by third parties, these links are provided for your information only.
                    We have no control over the contents of those sites or resources.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Law applicable
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    These Terms and Conditions and any dispute or claim arising out of or in connection with them (including non-contractual disputes or claims) will be governed by and construed in accordance with the laws of Kenya.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    The parties irrevocably agree that the courts of Kenya will have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with these Terms and Conditions (including non-contractual disputes or claims).
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    All disputes are subject, firstly, to mediation, and then by Arbitration by a sole arbitrator appointed by Cashlet. The venue of arbitration will be Nairobi, Kenya.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Contact us
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    To contact us, please email{' '}
                    <a className="text-primary-green underline font-semibold"
                      href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}
                    </a>. Thank you for visiting Cashlet
                  </p>
                </div>{' '}
              </div>
            </SectionItem>

            <SectionItem id="#section3" title=" Terms and conditions for investing">
              <div className=" space-y-8 ">
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Selecting savings and investment packages
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Through your Cashlet Account, you are able to self-select and invest in a number of Platform Products (starting with money market funds) that are available to our Customers.
                    Please see our blog for more information about the Platform products and how we have selected them.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If you are not sure whether Cashlet Account, the Cashlet Services, or the Platform Products (starting with money market funds) are suitable for you
                    then we strongly recommend that you seek advice from a qualified financial adviser.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We will not provide nor be responsible for providing you with any financial, legal or tax advice.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Initial amount
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    When you choose to purchase a new Platform Product you must initially commit the minimum amount required. This amount must be in your M-Pesa account
                    and you will have to set up an M-Pesa transfer as explained in App, and the amount you want to save will be moved to the collections account of the fund manager immediately.
                  </p>
                </div>{' '}
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Savings and Investment Cost and Charges
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We act at all times in accordance with all applicable laws and the CMA Rules, and we can charge your Cashlet Account should we incur in costs and charges we have incurred
                    as a result of one of the following events:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <li>we become liable to pay any tax or levies imposed on your Platform Product(s) or on any contribution paid by you; or </li>
                    <li>
                      we become liable to make any other payment to the Kenya Government or any governmental agency or any such self-regulatory organization,
                      to the extent directly or indirectly attributable to your Platform Product(s);
                    </li>
                    <li>Upon your consent, we are acting on your behalf as a withholding agent in Kenya.</li>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If we omit to deduct all or part of any charges from your Cashlet Account, on the due date, we shall not subsequently be prevented from deducting such
                    amounts or exercising any available remedy as a result of that omission.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    In line with what is required by the CMA, any fees for any part of the investment services will be outlined, in the App, before (ex ante) you enter into a transaction which
                    may incur such fees. You will also have all the necessary documentation of the breakdown  of the costs and charges you may incur should you choose to invest in any Cashlet product.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Equally you will be able to request your ex-post annual cost and charges statement which will be available by contacting{' '}
                    <a className="text-primary-green underline font-semibold"
                      href={`mailto:team@cashlet.co.ke?subject=${'Question about Cashlet'}&body=${''}`}>team@cashlet.co.ke{' '}
                    </a>,
                    which shows the effective cost and charges you incurred in relation to your investments for the past fiscal year.
                  </p>
                </div>{' '}

                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Placing instructions
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Our mobile application (but not our website) provide the functionality for you to place Instructions – we do not accept Instructions in any other way.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    When providing us with Instructions, you will be required to submit the cash amount which you wish to buy or sell. It is your responsibility to ensure
                    that the contents of your Instructions are accurate and complete in all respects. We do not examine or investigate the accuracy of your Instructions and
                    you hereby agree that we may assume, at all times, that your Instructions – as received by us – are correct and reflect your requirements.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You will bear all risk of sending Instructions to us, even if they are incorrect or do not reflect your requirements. You acknowledge and agree that we may
                    execute your Instructions exactly as we have received them, and agree that neither Cashlet nor any of our  directors, staff, agents, suppliers or contractors
                    will have any liability, of whatever nature, to you or anyone else for any Loss that you may suffer as a result of us executing your Instructions as we have received them.
                  </p>
                </div>{' '}

                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Pre-Sale Disclosure Requirements
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    You should ensure that you are aware of additional fees and charges imposed by the issuer of Platform products that you wish to buy, before dealing in those Platform products.
                    Examples include money market funds and similar investment products. Information regarding this will be made available in the relevant prospectus documents and on the websites
                    of fund managers/issuers. While Cashlet may facilitate access to this information (for example, by linking to the relevant documents from our mobile application or website),
                    we will not be held liable for our failure to do so or the contents of the information linked to.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Key Investor Information Documents (KIID), where available for certain savings and investment products, will be linked to from our mobile application or website and
                    will be available for you to review prior to placing an Instruction. By providing an Instruction, you confirm that you have read the relevant prospectus and KIID and
                    agree with its contents.
                  </p>
                </div>{' '}

                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Disinvestment
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If you instruct us from time to time to disinvest any of your Platform Product(s) we will do so under the mandate issued to us when you selected the investment.
                    We will continue to act upon other Orders that you provide to us (via the Cashlet Services). When we refund your money it will be an updated value of your investment
                    according to the performance of the product at that moment, net of the fees and charges applied on the investment product. The money so calculated will be returned into your Cashlet Account.
                  </p>
                </div>{' '}

                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Key risks
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Generally there are risks associated with investments.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    The investments offered by our fund managers are subject to loss in value over time. There can be no assurance that any appreciation
                    in the value of investments will occur.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    Any investment places your capital at risk, therefore, the value of investments and the income (if any) derived from them may fall as
                    well as rise in value and:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <li>You are not certain to make a profit</li>
                    <li>You may make a loss</li>
                    <li>You may lose your entire investment.</li>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    The past performance of your investments is not a guide to future performance. Neither Cashlet nor the fund managers guarantees
                    the performance of your Cashlet Account.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    The performance of an investment is dependent on its strategy, the skills and expertise of those responsible for managing its
                    investments, and the market(s) in which it invests and your instructions to Cashlet.
                  </p>
                </div>{' '}
              </div>
            </SectionItem>

            <SectionItem id="#section4" title="Terms and conditions of CMA Regulatory Sandbox">
              <div className=" space-y-8 ">
                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    About the CMA Regulatory Sandbox
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    We are part of the CMA regulatory sandbox in Kenya. The regulatory sandbox allows firms to test innovative offerings in a live
                    environment. More information on the CMA's regulatory sandbox can be found {' '}
                    <a
                      href="https://www.cma.or.ke/index.php/news-highhlights/454-press-release-cma-admits-sycamore-capital-ltd-to-regulatory-sandbox"
                      target="_blank"
                      className="text-primary-green underline font-semibold">
                      here
                    </a>
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    By using our service, you will be taking part in this regulatory test and you are bound by the Full Terms and Conditions.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    During this period, the application will still be under some development and testing and not everything will be perfect.
                    We anticipate the period that we will be under Sandbox program would be for 3 to 6 months.
                  </p>
                </div>{' '}

                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    Boundaries during the Sandbox period
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    During the sandbox period, certain restrictions will apply:
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <li>You are allowed a maximum deposit of KSh 70K per day and a maximum balance of Ksh 1M</li>
                    <li>
                      Where you fail to complete the identify verification process within 8 weeks, your account will be closed and any funds
                      in the account returned to the M-Pesa account you provided during sign-up.
                    </li>
                    <li>
                      The maximum number of users we will be accepting on the platform during this period is 5,000 users.
                      Consumers who apply for access to the Services after this quota has been reached will be put on a waiting list pending
                      when addition slots become available or after we successfully exit the Sandbox program
                    </li>
                  </ul>
                </div>{' '}

                <div className=" space-y-8 ">
                  <h3 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
                    What happens at the end of the Sandbox period
                  </h3>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    At the end of the Sandbox testing period we will communicate to you (via email, sms and phone)
                    to update you on outcome of the Sandbox testing and communicate the next steps.
                  </p>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If we are successful after the Sandbox period with our live testing, we will reach out to you and find out if you want
                    to be part of the migration to full public launch in Kenya
                  </p>
                  <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                    <li>
                      If you explicitly express interest to continue to use the Cashlet app, we will migrate your account from the Sandbox
                      environment onto the production environment. There will be no fundamental changes in the Terms and Conditions/ Privacy
                      policy governing the usage of Cashlet application and your funds will continue to reside with our fund management partners.
                    </li>
                    <li>
                      If you explicitly express that you are no longer interested to use the Cashlet application, we will liquidate your
                      investments (funds +gains/losses) with our partner fund managers and transfer to your M-Pesa and/or bank account.
                      All your data will be deleted from our servers in 30 days.
                    </li>
                    <li>
                      If you do not respond, we will assume that you are interested to continue to use the Cashlet app going forward and
                      therefore will be migrated from Sandbox to the production environment. You will have an option at any time to liquidate
                      all of your investments and close your account.
                    </li>
                  </ul>
                  <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    If we are unsuccessful at the end of the Sandbox period with our live testing, we will take a number of steps to discontinue the
                    Cashlet app and exit you from the app. We will liquidate all your investments with our partner fund managers and transfer your
                    balance with Cashlet at the date of discontinuation to your M-Pesa and/Bank account. All your data will be deleted from our servers.
                    All of this will happen within 30 days from when outcome of the Sandbox testing is received from the CMA. During this time you will be
                    able to reach our customer support with questions and will be guided throughout the process to ensure smooth discontinuation post the Sandbox period.
                  </p>
                </div>{' '}
              </div>
            </SectionItem>
          </section>
        </div>
      </div>
    </section>
  );
}

export default TermsAndConditionsPage;
