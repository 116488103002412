import React from "react";
import { Link } from "react-router-dom";
import footerPhone from "../../assets/img/footerPhone.svg";
import googlePlay from "../../assets/img/googlePlay.svg";
import StoresIcon from "../../assets/img/combo.png";
import { RegistrationModal } from "../emailModal/emailmodal";
import AppStoreIcon from "../../assets/img/apple.png";

function Banner() {
  const [WaitListModal, setWaitListModal] = React.useState(false);

  function ChangeHandler() {
    setWaitListModal(!WaitListModal);
    // console.log("pressed")
  }

  return (
    <div className="py-4  " style={{ backgroundColor: "#4B5E7F" }}>
      <RegistrationModal
        visibility={WaitListModal}
        handleChange={ChangeHandler}
      />

      <div className="max-w-7xl mx-auto xl:max-w-6xl 2xl:max-w-screen-xl px-5 md:px-8 py-2 md:py-0">
        <div className="flex flex-col lg:flex-row item-center justify-between relative">
          <div className="space-y-2">
            <h2 className="text-lg md:text-xl lg:text-2xl font-bold text-white">
              Are you Ready to start building your Future?
            </h2>
            <p className="text-gray-300 font-normal text-sm md:text-md">
              Download our app now to master your money management
            </p>

            {/* //1_0223 */}
            <div className="flex flex-col md:flex-row gap-3">
              <div className="flex  flex-row  pb-5 cursor-pointer ">
                <div
                  onClick={() => {
                    window.location.href =
                      "https://www.clkmg.com/Cashletapp/pswebsite";
                  }}
                  className=" flex flex-row rounded  w-48    shadow-lg overflow-hidden border-black border text-black font-bold bg-white"
                >
                  <img
                    className="h-12 w-17  justify-center pl-0 "
                    src={StoresIcon}
                  />

                  <div className="flex-column self-center pr-2 ">
                    <div className=" align-text-bottom my-auto font-medium tracking-tight text-xs">
                      Get it on
                    </div>
                    <div className=" align-text-bottom my-auto tracking-tight">
                      Google Play
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex  flex-row  pb-5 cursor-pointer ">
                <div
                  onClick={() => {
                    window.open(
                      "https://www.clkmg.com/Cashletapp/aswebsite",
                      "_blank"
                    );
                  }}
                  className=" flex flex-row rounded  w-48  py-1 md:py-0 shadow-lg overflow-hidden border-black border text-black font-bold bg-white"
                >
                  <img
                    className="h-7 w-7 mr-4 ml-4 mt-2 justify-center pl-0 "
                    src={AppStoreIcon}
                  />

                  <div className="flex-column self-center pr-2 ">
                    <div className=" align-text-bottom my-auto font-medium tracking-tight text-xs">
                      Get it on
                    </div>
                    <div className=" align-text-bottom my-auto tracking-tight">
                      App Store
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ml-auto -mt-20 md:-mt-36 lg:-mt-9 lg:mr-0 xl:-mr-12">
            <img
              src={footerPhone}
              alt="Brand2"
              className="block w-28 md:w-52 lg:w-64"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banner;
