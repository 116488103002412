import React from 'react';
import { Link } from 'react-router-dom';
import scan from '../../assets/img/scan.svg';
import cashBag from '../../assets/img/cash-bag.svg';
import risingArrow from '../../assets/img/rising-arrows.svg';
import ideaIcon from '../../assets/img/idea-icon.svg';
import Frame1665 from '../../assets/img/Frame1665.svg';
import groupPhoto1 from '../../assets/img/team/groupPhoto1.png';
import groupPhoto2 from '../../assets/img/team/groupPhoto2.png';
import member1 from '../../assets/img/team/member1.svg';
import member2 from '../../assets/img/team/member2.svg';
import member3 from '../../assets/img/team/member3.svg';
import member4 from '../../assets/img/team/member4.svg';
import member5 from '../../assets/img/team/member5.svg';
import Timeline from '../../components/Timeline/Timeline';
import ReactGA from 'react-ga'


function InfoItem({ children, title, description }) {
  return (

    <div className="flex flex-col">
      <div className="flex-shrink-0">
        <div className="flex items-center justify-end overflow-hidden h-32 w-32">{children}</div>
      </div>
      <div className="mt-4">
        <dt className="text-xl md:text-base font-bold text-gray-900">{title}</dt>
        <dd className="mt-2 text-base text-gray-500">{description}</dd>
      </div>
    </div>
  );
}

function AboutPage() {
  
  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])

  return (
    <section className="relative pt-12 md:pt-16">
      <div className="container  md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl  mx-auto mt-8 px-6 pb-10 md:mt-16 lg:mt-20 space-y-6">
        <h1 className="text-2xl tracking-tight font-bold sm:max-w-2xl md:max-w-4xl lg:max-w-5xl text-gray-900  md:text-4xl lg:text-3xl xl:text-4xl ">
          About us
        </h1>
        <div className="grid md:grid-cols-2 gap-8 font-body">
          <div className="space-y-6">
            <h3 className="text-lg tracking-wide font-bold  text-gray-900 ">
              Our mission is to help people invest, manage, and grow their money
              in the simplest way possible.
            </h3>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:mx-auto md:mt-5  lg:mx-0">
              While saving and investing have been thought to be complicated and
              stressful, we make it easy to understand, fun, and engaging.
            </p>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:mx-auto md:mt-5  lg:mx-0">
              Using a fully mobile-based app, we focus on helping you achieve
              your financial goals by giving you a platform that allows you to
              start with as little as Ksh. 100. You have the power to control
              how much you save, and where to invest and grow your balance.
            </p>
          </div>
          <div className="space-y-6">
            <p className="text-base text-gray-500 sm:mx-auto lg:mx-0">
              We are regulated as part of the Fintech Sandbox of the Capital
              Markets Authority (CMA) in Kenya. Our platform has investment
              products managed by ICEA Lion Asset Management, Etica Capital, and
              Orient Asset Managers; who are also authorized and regulated by
              the CMA. These products provide you with stability, high interest,
              and easy liquidity
            </p>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:mx-auto md:mt-5  lg:mx-0">
              We are not ‘just another savings app’, but a way for you to
              Manage, invest, and grow your money minus all the complications.
            </p>
          </div>
        </div>

        <div className="mt-10 space-y-6">
          <h1 className="text-2xl md:text-xl tracking-normal font-bold text-gray-900">
            What makes us different?
          </h1>
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-4 md:gap-x-8 md:gap-y-10">
            {" "}
            <InfoItem
              title="Low Minimum Amount"
              description="Start with Ksh. 100 and grow at your own pace and time. You are in full control of your money. Increase your deposits at any time and track your growth. "
            >
              <img className="h-full w-full" src={cashBag} alt="cashBag" />
            </InfoItem>{" "}
            <InfoItem
              title="Solid and Secure Returns "
              description="Your money is held by a custodian banks in Kenya and invested through fund managers that are regulated by the Capital Markets Authority (CMA) to provide interests of between 11-16% p.a."
            >
              <img className="h-full w-full" src={risingArrow} alt="scan" />
            </InfoItem>{" "}
            <InfoItem
              title="Fully Mobile-based"
              description="Start saving in 1-2-3  on your mobile phone. No long holdups and no pesky paperwork. Deposit and withdraw anytime via M-Pesa and do things on your terms. Withdraw anytime and have your money credited to your M-Pesa account within 24 hours."
            >
              <img className="h-full w-full" src={scan} alt="scan" />
            </InfoItem>{" "}
            <InfoItem
              title="Grow Your Knowledge"
              description="Connect with our customer support team through the in-app chat and grow your saving and investing knowledge from our advisors."
            >
              {" "}
              <img className="h-full w-full" src={ideaIcon} alt="scan" />
            </InfoItem>
          </dl>
        </div>
      </div>

      <div className="py-8 bg-primary-gray">
        <div className="container px-4 md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl mx-auto py-2 md:py-16">
          <div className="flex flex-row items-start justify-start flex-nowrap space-x-4">
            <svg
              className="w-2/12 md:w-1/12"
              viewBox="0 0 82 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.2 32.6C24.6 33.5333 28 35.5333 30.4 38.6C32.8 41.5333 34 45.1333 34 49.4C34 53.6667 32.6667 57.4 30 60.6C27.3333 63.6667 23.4 65.2 18.2 65.2C16.2 65.2 14.0667 64.8 11.8 64C9.66667 63.2 7.73333 61.8667 6 60C4.26667 58 2.8 55.4667 1.6 52.4C0.533333 49.3333 0 45.6 0 41.2C0 36.6667 0.733333 32.3333 2.2 28.2C3.66667 23.9333 5.66667 20.0667 8.2 16.6C10.7333 13 13.6 9.8 16.8 6.99999C20 4.2 23.3333 1.86667 26.8 0L34.4 10.4C30.2667 12.6667 26.6 15.6667 23.4 19.4C20.2 23.1333 18.1333 27.3333 17.2 32L20.2 32.6ZM67.4 32.6C71.8 33.5333 75.2 35.5333 77.6 38.6C80 41.5333 81.2 45.1333 81.2 49.4C81.2 53.6667 79.8667 57.4 77.2 60.6C74.5333 63.6667 70.6 65.2 65.4 65.2C63.4 65.2 61.3333 64.8 59.2 64C57.0667 63.2 55.1333 61.8667 53.4 60C51.6667 58 50.2 55.4667 49 52.4C47.9333 49.3333 47.4 45.6 47.4 41.2C47.4 36.6667 48.1333 32.3333 49.6 28.2C51.0667 23.9333 53 20.0667 55.4 16.6C57.9333 13 60.8 9.8 64 6.99999C67.2 4.2 70.5333 1.86667 74 0L81.6 10.4C77.6 12.6667 73.9333 15.6667 70.6 19.4C67.4 23.1333 65.3333 27.3333 64.4 32L67.4 32.6Z"
                fill="#25E37A"
              />
            </svg>
            <p className="w-11/12 text-2xl md:text-6xl font-bold text-gray-900 md:pr-20 mt-6 md:mt-16">
              Making it easy for everyone to save and take control of their
              money
            </p>
          </div>
        </div>
      </div>

      <div className="py-8 bg-white">
        <div className="container px-4 md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl mx-auto py-2 md:py-16">
          <Timeline />

          {/* <div className="grid grid-cols-1 md:grid-cols-6 gap-6">
            <div className="md:col-span-2 flex items-start ">
              <div className=" space-y-8 md:pr-16">
                <h2 className="text-2xl tracking-normal md:tracking-tight font-bold max-w-xs md:max-w-full text-gray-900">
                  Our team
                </h2>
                <p className=" text-base text-gray-500  sm:mx-auto ">
                  Do you like to challenge the norm and use your imagination to come up with creative solutions to
                  everyday problems? We are always looking for top-performers who are self-driven, dedicated, and ready
                  to work in a fast-paced vibrant tech environment.
                </p>
              </div>
            </div>
            <div className="md:col-span-4 md:pl-4 ">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:pr-32">
                <TeamCard
                  image={member1}
                  fullName="Kwaku Debrah"
                  role="CEO"
                  links={{
                    linkedin: '/',
                    instagram: '/',
                  }}
                />
                <TeamCard
                  image={member2}
                  fullName="Joseph Kitavi"
                  role="Software Engineer"
                  links={{
                    linkedin: '/',
                    instagram: '/',
                  }}
                />
                <TeamCard
                  image={member3}
                  fullName="George Otienu"
                  role="Software Engineer"
                  links={{
                    linkedin: '/',
                    instagram: '/',
                  }}
                />
                <TeamCard
                  image={member4}
                  fullName="Emily Gikonyo"
                  role="Customer Success"
                  links={{
                    linkedin: '/',
                    instagram: '/',
                  }}
                />
                <TeamCard
                  image={member5}
                  fullName="John Otienu"
                  role="Finance"
                  links={{
                    linkedin: '/',
                    instagram: '/',
                  }}
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div className="py-8 bg-primary-gray">
        <div className="px-4 max-w-5xl  mx-auto py-2 ">
          <div className="grid grid-cols-1 md:grid-cols-6 gap-6">
            <div className="md:col-span-9 flex items-center justify-center">
              <div className=" space-y-8 md:pr-16">
                <h2 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900">
                  Join Cashlet
                </h2>
                <p className=" text-base text-gray-500  sm:mx-auto ">
                  Do you like to challenge the norm and use your imagination to
                  come up with creative solutions to everyday problems? We are
                  always looking for top-performers who are self-driven,
                  dedicated, and ready to work in a fast-paced vibrant tech
                  environment.
                </p>
                <div>
                  <Link
                    className=" text-xl md:text-base text-primary-green underline font-bold md:font-semibold sm:mx-auto "
                    to="/join-team"
                  >
                    Join the team
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="md:col-span-3 relative">
              <img
                className="absolute  w-80 md:w-56 right-0 md:right-auto -top-40 md:top-14  z-auto"
                src={Frame1665}
                alt="cashBag"
              />
              <img
                className="hidden md:block h-full w-2/3 ml-auto  overflow-hidden relative"
                src={groupPhoto1}
                alt="cashBag"
              />
              <img className="md:hidden h-full w-full  overflow-hidden relative" src={groupPhoto2} alt="cashBag" />
            </div> */}
          </div>
        </div>
      </div>

      <div className="py-8 md:py-12 bg-white">
        <div className="max-w-5xl mx-auto space-y-8 md:space-y-16 px-5 md:px-8 lg:pb-2 pt-2">
          <div className="text-center">
            <h2 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900">
              We are working out of Nairobi, Kenya
            </h2>
          </div>

          <section className="align-center w-full  ">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  "<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8085577459315!2d36.790425074031404!3d-1.2890799986986687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10bacbeeff25%3A0xc13a221e31f03d7b!2sRegus%20-%20Nairobi%2C%20Laiboni%20Center!5e0!3m2!1sen!2ske!4v1684758969095!5m2!1sen!2ske' width='100%' height='450' className='' allowfullscreen='' loading='lazy' />",
              }}
            />
          </section>
        </div>
      </div>
    </section>
  );
}

export default AboutPage;
