import React from 'react';
import BlogItem from './BlogItem';

function BlogListSection({ articles = [] }) {
  const [openTab, setOpenTab] = React.useState(1);

  const articlesCount = Math.ceil(articles.length / 5);
  const rightArticles = articles.slice(0, articlesCount);

  // console.log(articles[0].image);//IMAGE DEBUG
  // console.log(articles);

  return (
    <>
      <div className="flex flex-wrap">
        <div className="">
          <ul className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row space-x-4" role="tablist">
            <li className="-mb-px mr-2 last:mr-0  text-center">
              <a
                className={
                  'tab active text-primary-gray-dark py-1 block hover:text-gray-800 text-base  sm:text-lg tracking-tight  focus:outline-none border-b-3 ' +
                  (openTab === 1 ? 'border-primary-green-dark font-bold' : 'border-none')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Recently added
              </a>
            </li>
            <li className="-mb-px mr-2 last:mr-0  text-center">
              <a
                className={
                  'tab active text-primary-gray-dark py-1 block hover:text-gray-800 text-base  sm:text-lg tracking-tight focus:outline-none border-b-3 ' +
                  (openTab === 2 ? 'border-primary-green-dark font-bold' : 'border-none')
                }
                onClick={(e) => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                Most Popular
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 ">
            <div className=" py-10 flex-auto">
              <div className="tab-content tab-space">
                <div className={openTab === 1 ? 'block' : 'hidden'} id="link1">
                  <div className="relative">
                    <div className="grid sm:grid-cols-6 sm:gap-10">
                      {articles.map((article, i) => {
                        return <BlogItem article={article} key={`article__${article.id}`} />;
                      })}

                      {articles.length === 0 && <p> No content</p>}
                    </div>
                  </div>
                </div>
                <div className={openTab === 2 ? 'block' : 'hidden'} id="link2">
                  <div className="relative">
                    <div className="grid sm:grid-cols-6 sm:gap-10">
                      {rightArticles.length > 0 &&
                        rightArticles.map((article, i) => {
                          return <BlogItem article={article} key={`article__${article.id}`} />;
                        })}
                      {rightArticles.length === 0 && <p> No content</p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BlogListSection;
