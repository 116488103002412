import React, { useState } from "react";
import { Regex } from "../../../utils/regex";

function SubscribeCard() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  const handleChange = (e) => {
    let value = e.target.value;
    setEmail(value);

    if (Regex.email.test(value)) {
      setError(false);
    }
  };

  const handleSubmit = () => {
    if (email && Regex.email.test(email)) {
      setIsLoading(true);
      setIsFailure(false);
      setIsSuccess(false);
      setError(false);

      const payload = {
        email,
        platform: "android"
      };

      fetch("https://cashlet-api-dev.herokuapp.com/api/public/mailing-list/add_to_both_access_list", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (response?.status === 204 || response?.status === 200) {
            setIsLoading(false);
            setIsSuccess(true);
            setTimeout(() => {
              setIsSuccess(false);
              setEmail("");
            }, 4000);
          } else {
            setIsLoading(false);
            setIsFailure(true);
            setTimeout(() => {
              setIsFailure(false);
            }, 4000);
          }
        })
        .catch((error) => {
          // console.log(error);
          setIsLoading(false);
          setIsFailure(true);
          setTimeout(() => {
            setIsFailure(false);
          }, 4000);
        });
    } else {
      setError(true);
    }
  };

  return (
    <div className="pt-4 w-full flex flex-col justify-center items-center">
      <div className="max-w-lg bg-primary-gray-light border-1 border-primary-gray-dark rounded-3xl overflow-hidden mx-auto">
        <div className="py-4 px-4 sm:px-8 mt-3">
          <div className="flex flex-col mb-8">
            <div className="text-gray-700 font-semibold text-2xl tracking-wide mb-2">
              Never miss a post
            </div>
            <p className="text-gray-500 text-base">
              Enter your email address to subscribe to this blog and receive
              notifications of new posts by email.
            </p>
          </div>
          <div className="col-span-6 sm:col-span-4">
            <label
              htmlFor="email_address"
              className="block text-sm font-medium text-gray-700"
            >
              Email address
            </label>
            <input
              type="text"
              name="email_address"
              id="email_address"
              autoComplete="email"
              placeholder="a.mihgkatga@gmail.com"
              value={email}
              onChange={handleChange}
              className="mt-1 px-2 py-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
            />
            {error ? (
              <div className="text-red-500 text-sm mt-1">
                *Please enter a valid email*
              </div>
            ) : null}
          </div>
          <div className="py-4">
            <button
              type="button"
              onClick={handleSubmit}
              disabled={isLoading || isSuccess || isFailure}
              className="block tracking-widest w-full capitalize text-center shadow bg-primary-green-dark hover:bg-indigo-700 focus:shadow-outline focus:outline-none text-white text-base py-3 px-10 rounded-3xl"
            >
              {isLoading ? (
                <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-white mx-auto"></div>
              ) : isSuccess ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="h-5 w-5 text-white"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    {" "}
                    <path stroke="none" d="M0 0h24v24H0z" />{" "}
                    <circle cx="12" cy="12" r="9" /> <path d="M9 12l2 2l4 -4" />
                  </svg>
                  <span className="ml-2 text-white">Subscribed</span>
                </div>
              ) : isFailure ? (
                <div className="flex items-center justify-center">
                  <svg
                    className="h-5 w-5 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>{" "}
                  <span className="ml-2 text-white">Failed</span>
                </div>
              ) : (
                "Subscribe"
              )}
            </button>
          </div>
          <div className="flex item-center space-x-1">
            <svg
              className="inline-flex items-center  h-5 w-7 text-gray-600 fill-current "
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.33333 3.06941V9.19275C1.33334 9.63172 1.44172 10.0639 1.64884 10.4509C1.85597 10.838 2.15543 11.1679 2.52067 11.4114L6 13.7314L9.47933 11.4114C9.84447 11.168 10.1439 10.8381 10.351 10.4512C10.5581 10.0643 10.6665 9.63227 10.6667 9.19341V3.06941L6 2.03341L1.33333 3.06941ZM0.522 1.88408L6 0.666748L11.478 1.88408C11.626 1.91699 11.7584 1.99939 11.8533 2.11768C11.9482 2.23598 12 2.38309 12 2.53475V9.19275C12 9.85124 11.8373 10.4995 11.5266 11.0801C11.2158 11.6606 10.7666 12.1555 10.2187 12.5207L6 15.3334L1.78133 12.5207C1.23352 12.1556 0.784307 11.6608 0.473565 11.0804C0.162824 10.5 0.000157571 9.85179 0 9.19341V2.53475C2.60252e-05 2.38309 0.0517586 2.23598 0.14666 2.11768C0.241561 1.99939 0.373958 1.91699 0.522 1.88408ZM6 9.00008L4.04067 10.0301L4.41467 7.84875L2.83 6.30341L5.02067 5.98475L6 4.00008L6.98 5.98475L9.17 6.30341L7.58533 7.84875L7.95867 10.0301L6 9.00008Z" />
            </svg>
            <p className=" inline-flex items-center  text-xs text-gray-600">
              Your email is safe with us. We commit to never sharing or selling
              your personal information.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribeCard;
