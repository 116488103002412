import { articleConstants } from '../_constants';

const initialState = {
  articles: [],
  singleArticle: {},
};

export function articleReducer(state = initialState, action) {
  switch (action.type) {
    case articleConstants.FILTER_ARTICLES_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
