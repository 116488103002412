import React, { useState } from "react";

function SearchForm(props) {
  const { searchArticle } = props;

  const [searchTerm, setSearchTerm] = useState("");

  const handleSubmit = (event) => {
    if (searchTerm) {
      event.preventDefault();
      searchArticle(searchTerm);
    }
  };

  return (
    <div className="relative text-primary-gray-dark border-b-1 border-primary-gray-dark w-full ">
      <form onSubmit={handleSubmit}>
        <input
          type="search"
          name="serch"
          placeholder="Find an Article"
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
          value={searchTerm}
          className="bg-transparent sm:bg-white h-10 rounded-full w-full placeholder-primary-gray-dark text-base focus:outline-none"
        />
        <button type="submit" className="absolute right-0 top-0 mt-3 mr-4">
          <svg
            className="h-5 w-5 fill-current"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 0C13.968 0 18 4.032 18 9C18 13.968 13.968 18 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0ZM9 16C12.867 16 16 12.867 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16ZM17.485 16.071L20.314 18.899L18.899 20.314L16.071 17.485L17.485 16.071Z"
              fill="#888DA8"
            />
          </svg>
        </button>
      </form>
    </div>
  );
}

export default SearchForm;
