import axios from 'axios/index';

// Backend API URL
export function getStrapiURL(path = '') {
  // console.log("strapiOnline",process.env.REACT_APP_BACKEND_URL);
  // console.log("strapi function",`http://localhost:1337/${path}`);
  // return `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:1337'}${path}`;
  // return `http://localhost:1337/${path}`;
  return `${process.env.REACT_APP_BACKEND_URL}${path}`;


}

// eslint-disable-next-line import/prefer-default-export
export const api = {
  articles: {
    filter: (params) => axios.get(getStrapiURL(`articles${params}`)),
    // get: (slug) => axios.get(getStrapiURL(`articles/${slug}`)),
    get: (slug) => axios.get(getStrapiURL(`articles/?slug=${slug}`)),

    getCategories: () => axios.get(getStrapiURL(`categories`))
  },
};
