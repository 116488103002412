import React, { useState } from "react";
import { Regex } from "../../utils/regex";

function NewsLetter() {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(false);

  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);

  const handleChange = (e) => {
    let value = e.target.value;
    setEmail(value);

    if (Regex.email.test(value)) {
      setError(false);
    }
  };

  const handleSubmit = () => {
    if (email && Regex.email.test(email)) {
      setIsLoading(true);
      setIsFailure(false);
      setIsSuccess(false);
      setError(false);

      const payload = {
        email,
        platform: "android"
      };

      fetch("https://cashlet-api-dev.herokuapp.com/api/public/mailing-list/add_to_both_access_list", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          if (response?.status === 204 || response?.status === 200) {
            setIsLoading(false);
            setIsSuccess(true);
            setTimeout(() => {
              setIsSuccess(false);
              setEmail("");
            }, 4000);
          } else {
            setIsLoading(false);
            setIsFailure(true);
            setTimeout(() => {
              setIsFailure(false);
            }, 4000);
          }
        })
        .catch((error) => {
          // console.log(error);
          setIsLoading(false);
          setIsFailure(true);
          setTimeout(() => {
            setIsFailure(false);
          }, 4000);
        });
    } else {
      setError(true);
    }
  };

  return (
    <div className="bg-primary-green">
      <div className="container max-w-7xl xl:max-w-6xl 2xl:max-w-screen-xl mx-auto px-5 md:px-8 py-14 w-full">
        <div className="flex flex-col md:flex-row gap-16">
          <div className="flex flex-col w-full md:w-1/2">
            <div className="text-lg md:text-xl lg:text-2xl font-bold text-white">
              Be a financial badass with our weekly newsletters! 
            </div>
            <div className="text-white font-normal text-sm md:text-md mt-4">
              Stay up to date with our financial literacy content.
            </div>
          </div>
          <div className="flex items-center w-full md:w-1/2 mt-3 md:mt-0">
            <div className="w-full">
              <div className="flex items-center mx-auto bg-white bg-opacity-30 rounded-md p-2">
                <div className="w-full">
                  <input
                    className="block p-3 pl-4 w-full text-sm text-white placeholder-white focus:ring-0 focus:ring-offset-0 focus:outline-none border-none focus:border-none bg-transparent"
                    placeholder="Your email..."
                    type="email"
                    value={email}
                    id="email"
                    name="email"
                    onChange={handleChange}
                    autoComplete="off"
                  />
                </div>
                <div>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={isLoading || isSuccess || isFailure}
                    className="py-3 px-4 w-full text-xs font-bold border hover:cursor-pointer bg-white rounded-md whitespace-nowrap"
                  >
                    {isLoading ? (
                      <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-b-2 border-primary-green"></div>
                    ) : isSuccess ? (
                      <div className="flex">
                        <svg
                          className="h-4 w-4 text-green-500"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="2"
                          stroke="currentColor"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          {" "}
                          <path stroke="none" d="M0 0h24v24H0z" />{" "}
                          <circle cx="12" cy="12" r="9" />{" "}
                          <path d="M9 12l2 2l4 -4" />
                        </svg>
                        <span className="ml-2 text-green-500">Subscribed</span>
                      </div>
                    ) : isFailure ? (
                      <div className="flex">
                        <svg
                          className="h-4 w-4 text-red-500"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>{" "}
                        <span className="ml-2 text-red-500">Failed</span>
                      </div>
                    ) : (
                      "Subscribe now"
                    )}
                  </button>
                </div>
              </div>

              {error ? (
                <div className="text-red-800">*Please enter a valid email*</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsLetter;
