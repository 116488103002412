import React from 'react';
import lock from '../../assets/img/lock.svg';
import pig from '../../assets/img/pig.svg';
import scan from '../../assets/img/scan.svg';

export function ArticleCard({ className, title, description, image }) {
  return (
    <article className={'bg-white w-full  lg:w-custom text-center text-primary-dark ' + className}>
      <div className="inline-flex overflow-hidden h-40 w-40">
        <img src={image} alt="" className="h-full w-full" />
      </div>
      <h2 className="md:mt-4 font-bold text-primary-darkest text-xl text-leading-8">{title}</h2>
      <p className="text-base text-primary-dark text-left py-3 px-3">{description}</p>
    </article>
  );
}

export default function Security() {
  return (
    <>
      <div className="pt-8 md:pt-12 lg:py-12 bg-white">
        <div className="max-w-7xl mx-auto space-y-8 md:space-y-16 px-5 md:px-8 lg:pb-2 pt-2">
          <div className="lg:text-center">
            <h2 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-3xl  capitalize">
              Your Safety and Security is our Priority
            </h2>
          </div>

          <section className="flex flex-col lg:flex-row items-start lg:justify-center w-full md:pr-16 lg:px-10 ">
            <ArticleCard
              className="shadow  px-2 md:px-8 lg:px-4 py-4 md:py-8 lg:py-10 mb-10  border border-gray-50 rounded-tl-lg  rounded-bl-lg rounded-tr-none"
              title="Your funds are secure"
              description="The investment products on Cashlet are managed by fund managers that are authorized and regulated by the Capital Markets Authority in Kenya."
              image={pig}
            />
            <ArticleCard
              className="shadow-lg px-2 md:px-8 lg:px-4  py-4 md:py-8 lg:py-16 lg:-mt-6 mb-10 rounded-lg  border border-gray-100 z-30"
              title="Your Data is Protected"
              description="We use strict procedures and security features to prevent unauthorized access. All your sensitive data and info are stored using state-of-the-art symmetric encryption (AES)."
              image={lock}
            />
            <ArticleCard
              className="shadow px-2 md:px-8 lg:px-4 py-4 md:py-8 lg:py-10 lg:mb-10 border border-gray-50 rounded-tr-lg  rounded-br-lg rounded-tl-none"
              title="Biometric authentication incorporated"
              description=" Access to your Cashlet account is secured with either a 4-digit passcode or your fingerprint."
              image={scan}
            />
          </section>
        </div>
      </div>
    </>
  );
}
