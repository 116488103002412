import { getStrapiURL } from '../_services/Api';

export function getStrapiMedia(media) {
  // console.log("chebio_mediajs", media);
  // const imageUrl = media.startsWith('/') ? getStrapiURL(media.url) : media.url;

  let imageUrl 
  if (media.startsWith('/')) {
     imageUrl= getStrapiURL(media.substr(1))
  } else{
    imageUrl= media
  }
  // console.log("chebio_StrapiMedia", imageUrl);
  return imageUrl;
}
