import React, { useEffect } from 'react';
import bg1 from '../../assets/img/Frame1668.svg';
import ReactGA from 'react-ga'


function JoinTeamPage() {

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [])
  
  return (
    <section className="relative pt-16 md:pt-20">
      <div className="max-w-7xl mx-auto px-8 py-12 relative">
        <div className="absolute lg:right-48 lg:top-16">
          <img className="md:w-44 lg:w-56" src={bg1} alt="backgroundimage" />
        </div>
        <div className="md:w-1/2 lg:text-left space-y-4 md:space-y-8">
          <h2 className="text-2xl md:text-2xl lg:text-4xl text-primary-darkest font-bold leading-8 tracking-wide capitalize">
            Join the Team
          </h2>
          <p className="leading-normal text-primary-dark  font-light text-base md:text-xl  slide-in-bottom-subtitle">
            We are always looking for talented and dedicated people to join our growing team so if you are interested in
            any of the roles on this page, get in touch on{' '}
            <a className="text-primary-green underline font-semibold" href="mailto: team@cashlet.co.ke">
              team@cashlet.co.ke
            </a>
          </p>
        </div>
        <div className="mt-6 md::mt-10 space-y-8 md:space-y-10 md:1/2 lg:w-2/3">
          <h2 className="text-xl lg:text-2xl text-primary-darkest font-bold leading-8 tracking-wide capitalize">
            Vacancies
          </h2>
          <dl className="space-y-8 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 gap-y-8 md:gap-y-10">
            <div className="flex flex-col shadow-lg rounded-lg border-4 border-primary-gray  py-6 px-6">
              <div className="text-left ">
                <dt className="text-lg leading-6 font-bold text-gray-900">
                  Backend engineer <br /> (Java)
                </dt>
              </div>
              <div className="text-right">
                <dd className=" text-base text-gray-800">Nairobi</dd>
              </div>
            </div>{' '}
            <div className="flex flex-col shadow-lg rounded-lg border-4 border-primary-gray  py-6 px-6">
              <div className="text-left ">
                <dt className="text-lg leading-6 font-bold text-gray-900">
                  Frontend engineer
                  <br /> (React Native)
                </dt>
              </div>
              <div className="text-right">
                <dd className=" text-base text-gray-800">Nairobi</dd>
              </div>
            </div>{' '}
            <div className="flex flex-col shadow-lg rounded-lg border-4 border-primary-gray  py-6 px-6">
              <div className="text-left ">
                <dt className="text-lg leading-6 font-bold text-gray-900">UI/UX Designer</dt>
              </div>
              <div className="text-right">
                <dd className=" text-base text-gray-800">Nairobi</dd>
              </div>
            </div>{' '}
            <div className="flex flex-col shadow-lg rounded-lg border-4 border-primary-gray  py-6 px-6">
              <div className="text-left ">
                <dt className="text-lg leading-6 font-bold text-gray-900">Security Engineer</dt>
              </div>
              <div className="text-right">
                <dd className=" text-base text-gray-800">Nairobi</dd>
              </div>
            </div>{' '}
            <div className="flex flex-col shadow-lg rounded-lg border-4 border-primary-gray  py-6 px-6">
              <div className="text-left ">
                <dt className="text-lg leading-6 font-bold text-gray-900">DevOps Engineer</dt>
              </div>
              <div className="text-right">
                <dd className=" text-base text-gray-800">Nairobi</dd>
              </div>
            </div>{' '}
            <div className="flex flex-col shadow-lg rounded-lg border-4 border-primary-gray  py-6 px-6">
              <div className="text-left ">
                <dt className="text-lg leading-6 font-bold text-gray-900">
                  Digital marketing analysts <br /> (Paid channels)
                </dt>
              </div>
              <div className="text-right">
                <dd className=" text-base text-gray-800">Nairobi</dd>
              </div>
            </div>{' '}
          </dl>
        </div>
      </div>
    </section>
  );
}

export default JoinTeamPage;
