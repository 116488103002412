import {} from "module";
import React from "react";
import bg5 from "../../assets/img/bg/bg-5.svg";
import bg6 from "../../assets/img/bg/bg-6.svg";
import bg7 from "../../assets/img/bg/bg-7.svg";
import bg8 from "../../assets/img/bg/bg-8.svg";
import FAQ from "./FAQ";

export default function Questions() {
  const questionList = [
    {
      id: 1,
      title: "How do I know Cashlet is right for me?",
      description:
        "Cashlet is designed for users seeking a modern, simple way to save and invest their money.",
    },
    {
      id: 2,
      title: "What is the cost of using Cashlet?",
      description:
        "Cashlet is entirely free of charge for any balances under KES 20,000. For balances over KES 20,000, an amount of KES 40 is deducted from your balance monthly. For all withdrawals and deposits, normal M-Pesa fees apply.",
    },
    {
      id: 3,
      title: "How does my money earn interest?",
      description:
        "When you invest in one of the money market funds (MMFs) on Cashlet, your funds are combined with funds from other investors and given out as loans to the Government, Banks and other reputable institutions. They pay interest to the MMF for this every day and your portion of the interest is passed on to you.",
    },
    {
      id: 4,
      title: "Where is your office?",
      description:
        "Our office address is 4th Floor, Laiboni Center, Lenana Road, Kilimani, Nairobi. We would love to host you when you are in the area and want to visit. Let us know.",
    },
    {
      id: 5,
      title: "How do I make a withdrawal?",
      description:
        "You can withdraw all or part of your funds any time you want into your M-pesa or Bank account. M-pesa withdrawals are instant but have a maximum withdrawal limit of KES 10,000 per day. Bank transfers have no maximum withdrawal limits but take up to 3 days (excluding weekends and holidays).",
    },
    {
      id: 6,
      title: "Is my money safe and secured with Cashlet?",
      description:
        "Yes. We are regulated under the Capital Markets Authority (CMA) in Kenya. We work with fund managers like ICEA Lion who have been around for 20+ years and are also regulated by the CMA. Also, your info is encrypted with bank-grade security encryption of the highest standard.",
    },
  ];

  return (
    <>
      <div
        className=" lg:py-10 pb-4 lg:pb-40 bg-white mt-12 md:mt-0"
        style={{ paddingTop: 0 }}
      >
        <div className="max-w-7xl mx-auto   px-5 pb-2 pt-2 md:px-16 xl:px-28 relative ">
          <div className="hidden md:block absolute  -left-40 xl:-left-10  xl:top-20">
            <img className="w-36  opacity-30" src={bg5} alt="backgroundimage" />
          </div>
          <div className="relative">
            <div className="hidden md:block absolute -left-24 lg:-left-36 md:-bottom-40 lg:-bottom-10">
              <img
                className="md:w-48 lg:w-68  opacity-40"
                src={bg7}
                alt="backgroundimage"
              />
            </div>

            <div className="lg:text-center relative">
              <div className="hidden md:block absolute md:right-10 lg:-right-20 md:top-72 lg:top-36">
                <img
                  className="md:w-44 lg:w-56   opacity-40"
                  src={bg8}
                  alt="backgroundimage"
                />
              </div>
              <div className="hidden md:block absolute md:-right-10 lg:-right-20 md:top-full lg:top-96">
                <img
                  className="w-32  opacity-40"
                  src={bg6}
                  alt="backgroundimage"
                />
              </div>
              <div className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-3xl   ">
                Our most frequently asked questions
              </div>
              <p className="text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-xl lg:mx-0">
                We guess you’ll probably want to know about the below. If you
                have more questions, read our{" "}
                <a
                  href="/FAQ"
                  className="text-primary-green font-semibold underline"
                >
                  full FAQs
                </a>
              </p>
            </div>
            <div className="md:mt-10 relative">
              <FAQ>
                {questionList.map((question) => (
                  <FAQ.QAItem id={question.id} key={question.id}>
                    <FAQ.Question
                      title={question.title}
                      answerId={question.id}
                    />
                    <FAQ.Answer id={question.id}>
                      {question.description}
                    </FAQ.Answer>
                  </FAQ.QAItem>
                ))}
              </FAQ>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
// bg-auto bg-no-repeat md:bg-cover bg-center //  //
