import React, { useState, useEffect } from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import graph from '../../assets/img/Group1558.png';


const graphStyling = {
  height: 660
}


export const Calculator = () => {
  const [startingAmount, setstartingAmount] = useState(5000);
  const [monthlyContribution, setmonthlyContribution] = useState(500);
  const [graphData, setgraphData] = useState(null);


  useEffect(() => {
    CalculateHandler()
  }, [])

  // useEffect(() => {
  //   console.log("the amount is: "+ startingAmount)
  // }, [startingAmount]); // Only re-run the effect if count changes


  const RoundOff = (x) => {
    return Math.trunc(x).toLocaleString();

  }


  //Network request to calculation endpoint handled at this point
  const CalculateHandler = () => {
    let JsonBody = {
      "bankAnnualInterestRate": 3,
      "currentBalance": startingAmount,
      "monthlyDeposit": monthlyContribution
    }



    //NETWORK REQUEST
    // fetch(`${process.env.REACT_APP_URL_prefix}api/public/investment-growth`, {//ERROR ON DEPLOYMENT
    fetch("https://cashlet-api-dev.herokuapp.com/api/public/investment-growth", {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(JsonBody)
    })
      .then((serverResponse) => {
        if (serverResponse.ok) {
          serverResponse.json()
            .then((jsonResponse) => {

              let Datasets = Object.keys(jsonResponse) //DATASET ARRAY FOR MULTI LINES
              let years = Object.keys(jsonResponse[Datasets[0]])// YEAR FOR GRAPH LABELLING
              let ArrayData = []

              years.forEach(yearElement => {
                let mergedObject
                let objectCreated = {}
                // year: yearElement
                // }

                let ItsIndex = years.indexOf(yearElement)
                let ArrayLength = years.length
                if (ItsIndex == 0 || ItsIndex == (ArrayLength - 1)) {
                  objectCreated.year = yearElement
                  // console.log("first and last"+ yearElement)

                } else {
                  let slicedSTring = "'" + yearElement.toString().slice(2)
                  objectCreated.year = slicedSTring

                  // let formattedYearLabel = 
                  // objectCreated.year = yearElement
                  // console.log("sliced string to: "+ slicedSTring)
                }
                Datasets.forEach(DatasetElement => {
                  let tempObj = { [DatasetElement]: jsonResponse[DatasetElement][yearElement] }
                  mergedObject = Object.assign(objectCreated, tempObj)
                })
                ArrayData.push(mergedObject)
              })
              console.log(ArrayData)//DEBUG
              setgraphData(ArrayData)
            })
        }
      });
  }

  function startingAmountChange(e) {
    setstartingAmount(e.target.value)
  }
  function monthlyContributionChange(e) {
    setmonthlyContribution(e.target.value)
  }

  return (
    <>
      <div className="pb-8 bg-white">
        <div className="max-w-7xl mx-auto px-5 md:px-8 pb-2 pt-2 ">
          <div className="lg:text-center space-y-4 md:space-y-8">
            <h2 className="text-2xl tracking-tight font-bold max-w-xs md:max-w-full text-gray-900 sm:text-5xl md:text-3xl " >
              Try our investment calculator
            </h2>
            <p className="lg:px-20 text-base tracking-normal text-gray-500 sm:mt-5  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-xl lg:mx-0  slide-in-bottom-subtitle">
              Take a look at how much your funds could be worth in 10 years if you save and invest through Cashlet.
              Enter the amounts you can deposit, and see what Cashlet could do for you"
            </p>
          </div>

          <div className="mt-10 ">
            <dl className="space-y-2  md:space-y-0 lg:grid md:grid-cols-6 lg:grid-cols-8 md:gap-x-8 md:gap-y-10">
              <div className="  flex flex-col col-span-6  lg:col-span-3 lg:w-full md:pr-10 space-y-6 ">
                <div className="w-full p-6 shadow-lg overflow-hidden sm:rounded-lg space-y-4  ">
                  <h2 className="text-lg md:text-2xl text-primary-darkest font-bold tracking-tight">
                    Enter details to preview
                  </h2>

                  <form className="flex flex-col flex-shrink-0 pt-2 space-y-4">
                    <div className="grid grid-cols-6 gap-2">
                      <div className="col-span-6">
                        <label
                          htmlFor="starting_amount"
                          className="block text-sm md:text-md font-medium text-primary-dark"
                        >
                          Starting amount
                        </label>
                        <input
                          id="starting_amount"
                          onChange={(e) => { startingAmountChange(e) }}
                          type="text"
                          className="placeholder-gray-400 placeholder-opacity-75 mt-1 focus:ring-indigo-500  p-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                          placeholder="KSH5000"

                        />
                      </div>
                      <div className="col-span-6">
                        <label
                          htmlFor="contribution"
                          className="block text-sm md:text-md font-medium text-primary-dark"
                        >
                          Monthly contributions
                        </label>
                        <input
                          id="contribution"
                          onChange={(e) => { monthlyContributionChange(e) }}
                          type="text"
                          placeholder="KSH500"
                          className=" placeholder-gray-400 placeholder-opacity-75 mt-1  focus:ring-indigo-500  p-3 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </form>
                  <div className="py-3">
                    <button
                      onClick={CalculateHandler}
                      className="w-full bg-primary-green text-white p-2 rounded-full font-semibold text-lg capitalize">
                      calculate
                    </button>
                  </div>
                </div>
                <p className=" invisible h-0 lg:visible max-w-lg text-xs text-primary-dark lg:mx-auto tracking-tight ">
                  Our projections are based on past performance of the money market funds on the Cashlet platform. Past
                  performance does not guarantee or indicate future results since investing involves risk and
                  investments may lose value.
                </p>
              </div>

              {/* <div className="flex col-span-6 bg-white  lg:col-span-5 ">
                <img className="w-full md:h-99" src={graph} alt="graph" />
               </div> */}
              <div className="  flex col-span-6   lg:col-span-5   ">
                <ResponsiveContainer
                  // height="95%"
                  width="100%"
                  minHeight={450}
                >
                  <LineChart data={graphData}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="0" vertical={false} />
                    <XAxis
                      dy={10}
                      // type={"category"}
                      domain={["2021", "2031"]}
                      // tickFormatter={(index, value)=>{
                      //   console.log("ondex"+ index)
                      //   // if (index == 2021) {
                      //   //   return value
                      //   // }
                      // }}
                      dataKey="year"
                    // minTickGap={30}
                    />
                    <YAxis
                      dx={20}
                      tickCount={8}
                      tickFormatter={(value) => {
                        if (value < 1000) return value
                        else if (value < 1000000) return `${value / 1000}K`
                        else return `${value / 1000000}M`
                      }}
                      orientation={"right"} axisLine={false} tickLine={false} />
                    <Tooltip
                      content={(active) => {
                        const Refortmat_TrancuatedYear = (yearPresented) => {
                          if (yearPresented == 2022 || yearPresented == 2032) {
                            return yearPresented
                          }
                          else {
                            return "20" + yearPresented.slice(1)
                          }

                        }
                        if (active.payload.length > 0) {
                          return (
                            <div className="w-full p-6 shadow-lg overflow-hidden sm:rounded-lg space-y-4 bg-gray-100">
                              <p className=" ">
                                Year {Refortmat_TrancuatedYear(active.label)}
                              </p>

                              <div
                                className="flex flex-col place-items-stretch"
                              >
                                <p className=" text-right text-green-400 sm:text-xs md:text-xs">
                                  Cashlet Account: {RoundOff(active.payload[0].value)}
                                </p>

                                <p className=" text-blue-900 sm:text-xs md:text-xs">
                                  Savings Account: {RoundOff(active.payload[1].value)}
                                </p>

                                <p className="text-gray-500 sm:text-xs md:text-xs">
                                  Current Account: {RoundOff(active.payload[2].value)}
                                </p>
                              </div>

                            </div>
                          )
                        } else {
                          return null
                        }
                      }}
                    />

                    <Legend
                      align="center"
                      formatter={(value, entry, index) => {
                        if (index == 0) {
                          return <span>Cashlet Account</span>;
                        } else if (index == 1) {
                          return <span>Bank savings account</span>;
                        } else if (index == 2) {
                          return <span>Bank current account</span>;
                        } else {

                        }
                      }}
                      iconType="plainline" />
                    <Line type="monotone" dataKey="cashletSavingAccount" stroke="#20E083" />
                    <Line type="monotone" dataKey="bankSavingAccount" stroke="#4B5E7F" />
                    <Line type="monotone" dataKey="bankCurrentAccount" stroke="#A0ADC3" />

                  </LineChart>
                </ResponsiveContainer>
              </div>
              <p className=" visible lg:invisible lg:h-0 max-w-sm text-xs text-primary-dark lg:mx-auto tracking-tight">
                Our projections are based on past performance of the money market funds on the Cashlet platform. Past
                performance does not guarantee or indicate future results since investing involves risk and
                investments may lose value.
              </p>
            </dl>
          </div>
        </div>
      </div>
    </>
  );
}
