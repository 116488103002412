import classNames from 'classnames';
import React, { useState } from 'react';

import upArrow from '../../assets/img/up-arrow.svg';
import bottomArrow from '../../assets/img/bottom-arrow.svg';

function FAQ({ children, defaultOpen = [0], open: openFromProps, onToggle: onToggleFromProps = () => {} }) {
  const isControlled = () => (openFromProps ? true : false);
  const [open, setIsOpen] = useState(defaultOpen);
  const getOpen = () => (isControlled() ? openFromProps : open);
  const isOpen = (index) => {
    return getOpen().includes(index) ? true : false;
  };
  const onToggle = (index) => {
    if (isControlled()) {
      onToggleFromProps(index);
    } else {
      if (getOpen().includes(index)) {
        setIsOpen(getOpen().filter((item) => item !== index));
      } else {
        setIsOpen([...getOpen(), index]);
      }

      onToggleFromProps(index);
    }
  };
  return (
    <section className="md:mx-8 space-y-6">
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          isOpen: isOpen(index),
          onToggle: () => onToggle(index),
        });
      })}
    </section>
  );
}

function Question({ title, isOpen, answerId, onToggle }) {
  return (
    <header
      className="flex justify-between items-start py-3 md:py-5 px-4 md:px-8  cursor-pointer select-none space-x-1 md:space-x-0"
      aria-expanded={isOpen}
      aria-controls={answerId}
      onClick={onToggle}
    >
      <span className="text-2xl tracking-tight font-bold w-3/4 text-gray-900 sm:text-5xl md:text-xl lg:text-2xl">
        {title}
      </span>
      <div className="border-none">
        {isOpen ? <img src={upArrow} alt="Up arrow" /> : <img src={bottomArrow} alt="Bottom arrow" />}

        {/* <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
          {isOpen ? (
            <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm5.247 15l-5.247-6.44-5.263 6.44-.737-.678 6-7.322 6 7.335-.753.665z" />
          ) : (
            <path d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm5.247 8l-5.247 6.44-5.263-6.44-.737.678 6 7.322 6-7.335-.753-.665z" />
          )}
        </svg> */}
      </div>
    </header>
  );
}

function Answer({ children, id, isOpen }) {
  const mergedClassname = classNames({
    hidden: !isOpen,
  });
  return (
    <div className={mergedClassname} id={id}>
      <div className="px-4 md:pl-8 pr-8 pb-5 text-primary-dark">
        <span className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
          {children}
        </span>
      </div>
    </div>
  );
}

function QAItem({ children, isOpen, onToggle }) {
  return (
    <article className=" py-4 ">
      <div className=" rounded-lg border-t-0 border border-b-4 bg-white  border-gray-400 border-opacity-25">
        {React.Children.map(children, (child, index) => {
          return React.cloneElement(child, {
            isOpen: isOpen,
            onToggle: onToggle,
          });
        })}
      </div>
    </article>
  );
}

FAQ.QAItem = QAItem;
FAQ.Question = Question;
FAQ.Answer = Answer;

export default FAQ;
