import React from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import CloseButton from '../../assets/img/closeButton.png'
import TikTokIcon from '../../assets/img/tiktok-round-icon.svg';



function BlogFooter() {

  const [WaitListModal, setWaitListModal] = React.useState(false)

  return (
    <footer className="relative pt-8 mb-6">

      <ReactModal
        shouldCloseOnOverlayClick={true}
        shouldCloseOnEsc={true}
        shouldReturnFocusAfterClose={true}
        preventScroll={true}
        isOpen={WaitListModal}
        ariaHideApp={false}
        className=" mt-52 rounded p-6 shadow-lg bg-white flex w-10/12 lg:w-1/3 mx-auto flex-col"
        style={{
          overlay: {
            backgroundColor: '#00000070'
          }
        }}>
        <div className=" w-full">

          <img
            className="relative right-5 h-10 w-10 hover:bg-green-400 rounded-lg "
            onClick={() => {
              setWaitListModal(!WaitListModal)
            }}
            src={CloseButton}
            alt="Play Video"
          />
          <div className="text-2xl tracking-tight font-semibold  text-gray-900 text-center mb-3 mt-1">
            Cashlet Waitlist
          </div>

          <div className="text-center" >
            We're bringing Cashlet to Android and iOS soon. Add your details below to get notified when it's available for download</div>
        </div>

        <div>

          <form className="flex flex-col flex-shrink-0 pt-2 space-y-4">
            <div className="grid grid-cols-6 gap-2">
              <div className="col-span-6 mb-3">
                <label
                  htmlFor="starting_amount"
                  className="block text-sm md:text-md font-medium text-primary-dark"
                >
                  Email
                </label>
                <input
                  id="starting_amount"
                  // onChange={(e) => { EmailHandler(e) }}
                  type="text"
                  className="placeholder-gray-400 placeholder-opacity-75 mt-1   p-3  block w-full shadow border border-gray-100 rounded"
                  placeholder="name@mail.com"

                />
              </div>
              <div className="col-span-6">
                <label
                  htmlFor="contribution"
                  className="block text-sm md:text-md font-medium text-primary-dark"
                >
                  Mobile number
                </label>
                <input
                  id="contribution"
                  // onChange={(e) => { PhoneNo_Handler(e) }}
                  type="text"
                  placeholder="07xxxxxxxx"
                  className="placeholder-gray-400 placeholder-opacity-75 mt-1 p-3  block w-full shadow border border-gray-100 rounded"
                />
              </div>
            </div>
          </form>

          <div className="py-3">
            <button
              // onClick={() => { MailChimpSubmitHandler() }}
              className="w-full bg-primary-green text-white p-2 rounded-full font-semibold text-lg capitalize mt-3">
              Join Waitlist
            </button>
          </div>
        </div>
      </ReactModal>

      <div className="container max-w-7xl xl:max-w-6xl 2xl:max-w-screen-xl mx-auto px-5 md:px-8 pb-2 pt-2">
        <div className="flex items-center  justify-between flex-wrap pb-2 space-y-4">
          <div className="sm:hidden flex flex-col space-y-10 ">
            <div className="mx-auto">
              <div className="">
                <a
                  href="https://web.facebook.com/cashletapp/"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/cashletapp/"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z" />
                  </svg>
                </a>
                <a
                  href="https://linkedin.com/company/cashlet"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
                  </svg>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCX_gBYtp_3X--RL0TvQ0olg"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <svg xmlns="https://www.youtube.com/channel/" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.441 16.892c-2.102.144-6.784.144-8.883 0-2.276-.156-2.541-1.27-2.558-4.892.017-3.629.285-4.736 2.558-4.892 2.099-.144 6.782-.144 8.883 0 2.277.156 2.541 1.27 2.559 4.892-.018 3.629-.285 4.736-2.559 4.892zm-6.441-7.234l4.917 2.338-4.917 2.346v-4.684z" />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/cashletapp"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z" />
                  </svg>
                </a>
                <a
                  href="https://www.tiktok.com/@cashletapp?_t=8cO01iuTzmQ&_r=1"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <img src={TikTokIcon} width="24" height="24"/>
                </a>
              </div>
            </div>
            <div className="space-y-2">
              <p>Copyright © 2020 Cashlet Money Moves, All rights reserved. </p>
              <p>Cashlet is registered with the Companies Registry with registration number: PVT-EYUMZ7M. </p>
            </div>
          </div>
          <div className="hidden sm:flex items-end  justify-between w-full">
            <div className="space-y-6">
              <Link
                className="flex items-center justify-around text-center px-4 lg:w-1/3 xl:w-1/2 py-3  text-md font-bold text-white bg-primary-green-dark border-b-4 border-gray-40  rounded-2xl hover:bg-gray-700 focus:outline-none focus:bg-gray-700"
                to="/"
                // onClick={() => {
                //   setWaitListModal(!WaitListModal)
                // }}
              >
                Visit our product page
              </Link>
              <div className="">
                <p>Copyright © 2020 Cashlet Money Moves, All rights reserved. </p>
                <div>Cashlet is registered with the Companies Registry with registration number: PVT-EYUMZ7M. </div>
              </div>
            </div>
            <div className="ml-auto">
              <div className="">
                <a
                  href="https://web.facebook.com/cashletapp/"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3 8h-1.35c-.538 0-.65.221-.65.778v1.222h2l-.209 2h-1.791v7h-3v-7h-2v-2h2v-2.308c0-1.769.931-2.692 3.029-2.692h1.971v3z" />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/cashletapp/"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z" />
                  </svg>
                </a>
                <a
                  href="https://linkedin.com/company/cashlet"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z" />
                  </svg>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCX_gBYtp_3X--RL0TvQ0olg"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <svg xmlns="https://www.youtube.com/channel/" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.441 16.892c-2.102.144-6.784.144-8.883 0-2.276-.156-2.541-1.27-2.558-4.892.017-3.629.285-4.736 2.558-4.892 2.099-.144 6.782-.144 8.883 0 2.277.156 2.541 1.27 2.559 4.892-.018 3.629-.285 4.736-2.559 4.892zm-6.441-7.234l4.917 2.338-4.917 2.346v-4.684z" />
                  </svg>
                </a>
                <a
                  href="https://twitter.com/cashletapp"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.066 9.645c.183 4.04-2.83 8.544-8.164 8.544-1.622 0-3.131-.476-4.402-1.291 1.524.18 3.045-.244 4.252-1.189-1.256-.023-2.317-.854-2.684-1.995.451.086.895.061 1.298-.049-1.381-.278-2.335-1.522-2.304-2.853.388.215.83.344 1.301.359-1.279-.855-1.641-2.544-.889-3.835 1.416 1.738 3.533 2.881 5.92 3.001-.419-1.796.944-3.527 2.799-3.527.825 0 1.572.349 2.096.907.654-.128 1.27-.368 1.824-.697-.215.671-.67 1.233-1.263 1.589.581-.07 1.135-.224 1.649-.453-.384.578-.87 1.084-1.433 1.489z" />
                  </svg>
                </a>
                <a
                  href="https://www.tiktok.com/@cashletapp?_t=8cO01iuTzmQ&_r=1"
                  className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  type="button"
                  target="_blank"
                >
                  <img src={TikTokIcon} width="24" height="24"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default BlogFooter;
