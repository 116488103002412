import React from 'react';

function CardFeatures({
  className,
  title,
  titleClass,
  image,
  bgImage,
  bgClass,
  bgClassImg,
  bgImage2,
  bgClass2,
  bgClassImg2,
  bgImage2Visible = false,
  bgImageVisible = false,
  textLeft = true,
  description = [],
}) {
  function sectionLeft(cl = 'ml-auto') {
    return (
      <div className={'relative w-full lg:w-6/12  px-0 md:px-4 lg:px-12 ' + cl}>
        {bgImageVisible && (
          <div className={bgClass}>
            <img className={bgClassImg} src={bgImage} alt="backgroundimage" />
          </div>
        )}
        <div className="space-y-4 md:space-y-8">
          <h3 className={'text-2xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-3xl ' + titleClass}>
            {title}
          </h3>
          {description.length > 0 &&
            description.map((i, index) => (
              <p
                key={index}
                className="text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-4xl sm:mx-auto md:mt-5 md:text-lg lg:mx-0 text-left  slide-in-bottom-subtitle"
              >
                {i}
              </p>
            ))}
        </div>

        {bgImage2Visible && (
          <div className={bgClass2}>
            <img className={bgClassImg2} src={bgImage2} alt="backgroundimage" />
          </div>
        )}
      </div>
    );
  }
  function sectionRight(cl = 'ml-auto') {
    return (
      <div className={'w-full  lg:w-5/12  ' + cl}>
        <img alt="..." className="w-full" src={image} />
      </div>
    );
  }

  return (
    <section className={className}>
      <div className="container mx-auto px-5 md:px-8 pb-2 pt-2">
        <div className=" flex flex-wrap items-center">
          {textLeft ? (
            <div className="items-center flex flex-wrap space-y-8">
              {sectionLeft('lg:ml-auto lg:pr-0 lg:pl-20')}
              {sectionRight('lg:ml-auto ')}
            </div>
          ) : (
            <div className="items-center flex flex-wrap">
              {sectionRight('lg:ml-auto')}
              {sectionLeft('lg:mr-auto lg:pl-0 lg:pr-20 ')}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

CardFeatures.propTypes = {};

export default CardFeatures;
