import React from "react";
import CustomerOneImage from "../../assets/img/testimonials/customer_one.png";
import CustomerTwoImage from "../../assets/img/testimonials/customer_two.png";
import CustomerThreeImage from "../../assets/img/testimonials/customer_three.png";
import "./testimonials.css";

const testimonies = [
  {
    image: CustomerOneImage,
    name: "Allan M",
    message:
      "Cashlet enabled me to go from financial insecurity to total confidence in just days. Cool thing? I easily get access to my money, keep sight of my financial goal, and get incredible 1on1 support from the team even on a weekend - Better than my previous national bank LOL!",
  },
  {
    image: CustomerThreeImage,
    name: "David N",
    message:
      "I'm absolutely thrilled with Cashlet, the financial app that has revolutionized my financial management! This app is a true game-changer, providing an exceptional user experience and an array of features that have transformed the way I handle my finances. Allow me to share my thoughts on why Cashlet deserves five stars! First and foremost, Cashlet's user interface is sleek, intuitive, and visually appealing. Navigating through the app is a breeze.",
  },
  {
    image: CustomerTwoImage,
    name: "Ruth N",
    message:
      "The experience with you guys is superb. The Cashlet app has been as fast as light so thumbs up 👍👍. In case of any small issue which is rare to happen, they're all always online to help and serve us with a lot of honesty.",
  },
];

function Testimonials(props) {
  return (
    <div
      style={{ backgroundColor: "white" }}
    >
      <div className="container max-w-7xl xl:max-w-6xl 2xl:max-w-screen-xl mx-auto px-5 md:px-8 py-14 w-full">
        <div className="mb-10 text-center text-2xl font-bold text-gray-900 sm:text-5xl md:text-3xl">
          Testimonials
        </div>
        <div className="flex flex-col md:flex-row gap-4 testimonials-text-style">
          {testimonies?.map((item, index) => {
            return (
              <div
                key={index}
                className="w-full md:w-1/3 bg-gray-50 rounded-3xl px-10 py-14"
              >
                <div className="mb-6">
                  <img
                    src={item.image}
                    alt="profile"
                    className="rounded-full h-20 w-20"
                  />
                </div>
                <div className="mb-4 font-extrabold text-2xl">{item.name}</div>
                <div className="mb-2 text-gray-700 text-md line-clamp tracking-wide">{item.message}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
