// import { id } from 'postcss-selector-parser';
import React, { useEffect, useState, useReducer } from 'react';
import { Link } from "react-scroll";
import collapse from "../../assets/img/collapse.png"
import expand from "../../assets/img/expand.png"
import ReactGA from 'react-ga'




function SectionItem({ children, id, title }) {
  return (
    <div id={id}>
      {/* <!--Title--> */}
      <h2 className="font-bold break-normal text-gray-900 py-2 pb-2 md:pb-8 text-2xl md:text-5xl">{title}</h2>

      {/* <!--Card--> */}
      {children}
      {/* <!--/Card--> */}

      {/* <!--divider--> */}
      <hr className="bg-gray-200 my-5 md:my-12 py-px opacity-25" />
    </div>
  );
}

function FAQPage() {
  let initialState = false


  let isMobile = window.innerWidth < 767
  if (isMobile) {
    initialState = true
  }
  console.log(isMobile)

  const [SectionVisibility, setSectionVisibility] = React.useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      Section1_1: false,
      Section1_2: initialState,
      Section1_3: initialState,
      Section1_4: initialState,
      Section1_5: initialState,
      Section1_6: initialState,
      Section1_7: initialState,
      Section1_8: initialState,
      Section1_9: initialState,
      Section1_10: initialState,
      Section1_11: initialState,
      Section1_12: initialState,
      Section1_13: initialState,
      Section1_14: initialState,
      Section1_15: initialState,
      Section1_16: initialState,
      Section1_17: initialState,
      Section1_18: initialState,
      Section1_19: initialState,
      Section1_20: initialState,
      Section1_21: initialState,
      Section1_22: initialState,
      Section1_23: initialState,
      Section1_24: initialState,
      Section1_25: initialState,
      Section1_26: initialState,
      Section1_27: initialState,
      Section1_28: initialState,
      Section1_29: initialState,
      Section1_30: initialState,
      Section1_31: initialState,
      Section1_32: initialState,
      Section1_33: initialState,
      Section1_34: initialState,
      Section1_35: initialState,
      Section1_36: initialState
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    console.log("google faq triggered");
  }, [])

  // useEffect(() => {
  //   window.addEventListener(
  //     "resize",
  //     () => {
  //       const screenWidth = window.innerWidth < 767;
  //       if (screenWidth !== isMobile) setState("isMobile", screenWidth);
  //     },
  //     initialState
  //   );
  // }, [isMobile]);




  return (
    <section className="relative py-0 md:py-16">
      <div className="max-w-6xl md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl  mx-auto">
        {/* -------------------------------------------------- */}
        <div className="container w-full flex flex-wrap mx-auto px-4 md:px-2 pt-0 lg:pt-16 mt-16">
          {/* -------------------------------------------------- */}
          <div className="w-full lg:w-2/6 px-6 text-xl text-gray-800 leading-normal">
            <div
              className="w-full  sticky inset-0 hidden max-h-full lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-2 lg:my-0 border border-gray-400 lg:border-transparent bg-white shadow lg:shadow-none lg:bg-transparent z-20"
              style={{ top: "6em" }}
              id="menu-content"
            >
              <h3 className="text-base font-bold text-gray-700">FAQ</h3>
              <h1 className="text-2xl font-bold py-2 lg:pb-8 text-gray-700">
                Table of Contents
              </h1>
              <ul className="list-reset py-2 md:py-0">
                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section1"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">
                    What is Cashlet
                  </span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section2"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">
                    Safety & Security
                  </span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section3"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">
                    Identity verification
                  </span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section4"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Savings goals</span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section5"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">
                    Savings and interest
                  </span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section6"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">
                    Money market funds
                  </span>
                </Link>

                <Link
                  activeClass="font-bold border-primary-green border-l-4 border-transparent"
                  className="py-2 cursor-pointer block pl-4 align-middle text-gray-700 no-underline hover:text-gray-800 border-l-4 border-transparent border-gray-50"
                  to="#section7"
                  spy={true}
                  smooth={true}
                  offset={-80}
                >
                  <span className="pb-1 md:pb-0 text-base">Withdrawals</span>
                </Link>
              </ul>
            </div>
          </div>
          {/* -------------------------------------------------- */}
          <section className="container w-full lg:w-4/6">
            <div className=" lg:hidden text-base tracking-tight mt-1 mb-1.5 font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
              FAQ
            </div>
            <SectionItem id="#section1" title="What is Cashlet">
              <div className=" space-y-4 md:space-y-8 ">
                <div className=" space-y-4 md:space-y-1 pt-8 ">
                  <div className="flex flex-row relative ">
                    <h3 className=" text-base tracking-tight w-10/12 md:w-full  font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      What is Cashlet?
                    </h3>

                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_1"]: !SectionVisibility.Section1_1,
                            });
                          }}
                          src={SectionVisibility.Section1_1 ? expand : collapse}
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={
                      SectionVisibility.Section1_1 ? "hidden" : "visible "
                    }
                  >
                    <p className="  text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Cashlet is a Kenya-based personal finance mobile app that
                      helps you put your finances in order by helping you to
                      save and invest your money through a tech-driven solution.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Our mission is to help people save, invest, manage, and
                      grow their money in the simplest way possible. While
                      saving and investing has always been thought of as
                      complicated and stressful, we take away the stress by
                      giving you convenience, security, and an easy-to-use
                      platform.{" "}
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Cashlet is owned by Sycamore Capital Kenya Limited is
                      regulated by the{" "}
                      <a
                        href="https://www.cma.or.ke/cma-graduates-sycamore-capital-limited-from-the-regulatory-sandbox/"
                        target="_blank"
                        className="text-primary-green underline font-semibold"
                      >
                        Capital Market Authority (CMA) as an intermediary
                        service platform provider
                      </a>
                    </p>
                  </div>

                  <div className="border-b-4 border-transparent border-gray-50" />
                </div>{" "}
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full  font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Why should I save on Cashlet?
                    </h3>

                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_2"]: !SectionVisibility.Section1_2,
                            });
                          }}
                          src={SectionVisibility.Section1_2 ? expand : collapse}
                          alt=""
                          className="absolute inset-y-1 right-2 "
                        />
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={
                      SectionVisibility.Section1_2 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Are you thinking of about saving for major things like
                      housing, building emergency funds, or retirement nest
                      eggs? That’s where Cashlet comes in. It helps you create
                      goals, target a specific amount for each goal, and stay
                      focused as you save and invest. You can choose to save
                      little amounts of money periodically (Daily, Weekly or
                      Monthly) towards each goal that you set up.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Cashlet is suitable for anyone who:
                    </p>
                    <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                      <li>
                        Wants to save and invest even while they are asleep{" "}
                      </li>
                      <li>
                        Wants to manage his/her money convenient on a mobile app
                        and hates the old way of saving and investing -lots of
                        paperwork, queues, phone/email exchanges, delays, and
                        unresponsive customer support
                      </li>
                      <li>
                        Never wants to miss their savings and investment goals
                        again
                      </li>
                    </ul>
                  </div>
                </div>
                <div className=" border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full  font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How do I start saving and investing on Cashlet?
                    </h3>

                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_3"]: !SectionVisibility.Section1_3,
                            });
                          }}
                          src={SectionVisibility.Section1_3 ? expand : collapse}
                          alt=""
                          className="   absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={
                      SectionVisibility.Section1_3 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Signing up is easy, free and carries no obligations. To
                      start saving and investing on Cashlet:
                    </p>
                    {/* CAS-482 SPRINT N'DJAMENA */}
                    {/* <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                    </p> */}
                    <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                      <li>
                        Download the app from the Google PlayStore and Apple
                        AppStore.
                      </li>
                      <li>
                        Set up your first savings goal create an account with
                        your persona details.{" "}
                      </li>
                      <li>
                        Verify your identity using your ID and KRA pin so we
                        know you are who you say you are.
                      </li>
                      <li>
                        Enter your M-Pesa number and then make your first
                        deposit.
                      </li>
                    </ul>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      And after that, you can close your savings goals and
                      withdraw money whenever you decide.
                    </p>
                  </div>
                </div>
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Can I get a loan on Cashlet?
                    </h3>

                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_4"]: !SectionVisibility.Section1_4,
                            });
                          }}
                          src={SectionVisibility.Section1_4 ? expand : collapse}
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={
                      SectionVisibility.Section1_4 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      No. Cashlet is an app that helps you to save, invest and
                      grow your money. We do not give loans.
                    </p>
                  </div>
                </div>
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Is Cashlet regulated? How do I know this is not a scam?
                    </h3>

                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_5"]: !SectionVisibility.Section1_5,
                            });
                          }}
                          src={SectionVisibility.Section1_5 ? expand : collapse}
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={
                      SectionVisibility.Section1_5 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Yes. Cashlet is regulated by the Capital Market Authority
                      (CMA) of Kenya. You can look this up or find further
                      information directly on the{" "}
                      <a
                        // href="https://cma.or.ke/index.php?option=com_content&view=article&id=531:cma-regulatory-sandbox-ready-to-receive-applications&catid=12&Itemid=207" //outdated not found
                        href="https://www.cma.or.ke/cma-graduates-sycamore-capital-limited-from-the-regulatory-sandbox/"
                        target="_blank"
                        className="text-primary-green underline font-semibold"
                      >
                        CMA’s website.
                      </a>
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Will Cashlet charge me for anything?
                    </h3>

                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_6"]: !SectionVisibility.Section1_6,
                            });
                          }}
                          src={SectionVisibility.Section1_6 ? expand : collapse}
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_6 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Cashlet is entirely free of charge for any balances under
                      KES 20,000. For balances over KES 20,000, an amount of KES
                      40 is deducted from your balance monthly. For all
                      withdrawals and deposits, normal M-Pesa fees apply.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      What mobile platforms does Cashlet support?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_7"]: !SectionVisibility.Section1_7,
                            });
                          }}
                          src={SectionVisibility.Section1_7 ? expand : collapse}
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_7 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      The Cashlet app is available for free in the Google Play
                      Store. We’re continually working on the app, and we
                      release regular updates. If you’re looking for a
                      particular feature or have feedback or suggestions for the
                      app, we will love to hear it.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      The app is not current available on the Apple AppStore but
                      we are working on this and we hope to release it in the
                      near future.
                    </p>
                  </div>
                </div>
              </div>
            </SectionItem>
            <SectionItem id="#section2" title="Safety & Security">
              {/* <div className=" space-y-8 ">
                <div className=" space-y-8 "> */}
              <div className=" space-y-4 md:space-y-8 ">
                <div className=" space-y-4 md:space-y-1 pt-2 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How safe is Cashlet?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_10"]: !SectionVisibility.Section1_10,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_10 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_10 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      We take security and privacy very seriously at Cashlet and
                      it is our top priority. All your financial information is
                      secured with bank-level encryption technology of the
                      highest standards.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      In addition, Cashlet does not hold your funds. When you
                      make a deposit, the funds move directly into the
                      collection bank account of our partner fund managers –
                      ICEA Lion Asset Management, Etica Capital and Orient Asset Managers. These are all established fund managers who are
                      monitored and regulated by the Capital Markets Authority
                      (CMA) of Kenya.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      What is the passcode?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_11"]: !SectionVisibility.Section1_11,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_11 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_11 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      This is a 4 digit unique passcode that you set up when
                      creating an Cashlet account. It is used to secure your
                      account and this is what you will use for logging into
                      your account, and initiating transfers and withdrawals on
                      your Cashlet account.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Instead of using the 4-digit passcode, you can also choose
                      to secure your passcode using your fingerprint.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Does Cashlet have an office?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_12"]: !SectionVisibility.Section1_12,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_12 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_12 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Yes! We are located on 4th Floor, Laiboni Center, Lenana
                      Road, Kilimani, Nairobi.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Contact Number:{" "}
                      <span className="font-semibold">0112029738 </span> <br />
                      Email:{" "}
                      {/* <a href="/FAQ" className="text-primary-green underline font-semibold">
                        team@cashlet.co.ke
                      </a> */}
                      <a
                        className="text-primary-green underline font-semibold"
                        href={`mailto:team@cashlet.co.ke?subject=${"Question about Cashlet"}&body=${""}`}
                      >
                        team@cashlet.co.ke{" "}
                      </a>
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How secure is my money?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_13"]: !SectionVisibility.Section1_13,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_13 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_13 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      We take security of your money very seriously at Cashlet
                      and it is our top priority.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      We operate in partnership with 2 established fund managers
                      in Kenya – ICEA Lion Asset Management, Etica Capital and Orient Asset Managers. Based on your choice, your funds
                      are placed and warehoused in the money market funds
                      managed by one of these fund managers. These money market
                      funds are all approved, monitored and regulated by the
                      Capital Markets Authority (CMA) of Kenya so your funds are
                      under secure watch.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      So Cashlet does not hold your funds. When you make a
                      deposit, the funds move directly from your M-Pesa account
                      into the collection bank account of these partner fund
                      managers at Stanbic Bank, or Equity Bank.
                    </p>
                  </div>
                </div>
              </div>
            </SectionItem>
            <SectionItem id="#section3" title="Identity verification">
              {/* <div className=" space-y-8 ">
                <div className=" space-y-8 "> */}
              <div className=" space-y-4 md:space-y-8 ">
                <div className=" space-y-4 md:space-y-1 pt-2 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      What is identify verification?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_14"]: !SectionVisibility.Section1_14,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_14 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_14 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Identify verification is a simple process that helps us
                      verify that ensure that you are who you say you are, and
                      to add extra security to your account.. It is quick and
                      takes no more than 2 minutes to complete.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      We do this for business reasons and to comply with
                      relevant government requirements. It allows us to process
                      new users efficiently, and to ensure that we eliminate
                      fraudulent applications or those that pose a risk of money
                      laundering.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      What information do I need to submit to verify my
                      identify?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_15"]: !SectionVisibility.Section1_15,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_15 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_15 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      There are 2 things you need to submit as part of the
                      identity verification process:
                    </p>
                    <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                      <li>
                        Photo of your National ID or Passport: This helps us to
                        verify that the information that your provide about
                        yourself are correct and that you are real.
                      </li>
                      <li>
                        Your KRA pin: This helps us to fulfil out tax
                        obligations as required by the Government of Kenya.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How do I submit the information?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_16"]: !SectionVisibility.Section1_16,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_16 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_16 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      All the information is submitted via the Cashlet app. It
                      is a simple process that does not take more than 2
                      minutes.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      All of this information are saved securely using
                      bank-level encryption and we don’t share it with anyone.
                    </p>
                  </div>
                </div>
              </div>
            </SectionItem>
            <SectionItem id="#section4" title="Savings goals">
              {/* <div className=" space-y-8 ">
                <div className=" space-y-8 "> */}
              <div className=" space-y-4 md:space-y-8 ">
                <div className=" space-y-4 md:space-y-1 pt-2 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      What is a savings goal?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_17"]: !SectionVisibility.Section1_17,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_17 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={
                      SectionVisibility.Section1_17 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Whatever the reason you are saving for e.g. buying a
                      house, going on a vacation, planning for a wedding, or
                      buying a new car, Cashlet help you set and track your
                      goals. When you blindly saving money without giving your
                      savings a name, it is likely that you will not have the
                      discipline to keep putting money aside for it.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      In order to help with this, Cashlet allows you to create
                      specifically name goals, target a specific amount for each
                      goal, and stay focused as you save and invest.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How many savings goals can I create?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_18"]: !SectionVisibility.Section1_18,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_18 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_18 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      You can create as many savings goals as you wish. Housing
                      plan, New Car goal, Wedding funds, Travel goal, Emergency
                      fund and so on. You have flexibility to give each plan a
                      unique name that resonates with your financial goals.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How do I create a savings goal?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_31"]: !SectionVisibility.Section1_31,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_31 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_31 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Its really simple.
                    </p>
                    <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                      <li>
                        Log in to the Cashlet app and click on “”Add another
                        goal” sign on the home dashboard.
                      </li>
                      <li>
                        Select the type of goal you want to create, enter a
                        customized name for the goal.
                      </li>
                      <li>
                        Enter the target amount for the goal and select the
                        money market fund that you want all funds you deposit
                        into goal to be invested in. This is important because
                        it allows your funds to earn interest so that you reach
                        your target amount quickly.
                      </li>
                      <li>
                        Review the details of the goal and click on the “Create
                        new goal” button, and Voila… it’s done.
                      </li>
                    </ul>
                  </div>
                </div>{" "}
              </div>
            </SectionItem>
            <SectionItem id="#section5" title="Savings and interest">
              {/* <div className=" space-y-8 ">
                <div className=" space-y-8 "> */}
              <div className=" space-y-4 md:space-y-8 ">
                <div className=" space-y-4 md:space-y-1 pt-2 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      What is the minimum deposit that I can start with?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_19"]: !SectionVisibility.Section1_19,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_19 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_19 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      You can start saving with as little as KSH 100.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative">
                    <h3 className="  text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Do I earn interest or returns on Cashlet?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_20"]: !SectionVisibility.Section1_20,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_20 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_20 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Yes!
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      The current interest rate is between 11 to 15% p.a.
                      depending on the money market fund that you select.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How is my interest paid?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_21"]: !SectionVisibility.Section1_21,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_21 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_21 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Interest is accrued daily on your balance, and reflects on
                      your dashboard at 10am every day. You can withdraw all or
                      part of the interest at the end of each month.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      When does my savings begin to earn interest?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_22"]: !SectionVisibility.Section1_22,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_22 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_22 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Your funds start to earn interest within 2 working days
                      after you make a deposit. Your daily returns are credited
                      to your Cashlet account by 10 am each day.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How is Cashlet able to offer higher interest/returns than
                      traditional banks?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_23"]: !SectionVisibility.Section1_23,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_23 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_23 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      This is simple.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Firstly, because we are fully digital, we do not have the
                      high cost base of traditional banks. Our operations are
                      lean, driven by our proprietary mobile technology.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Second, when you invest in one of the money market funds
                      (MMFs) on Cashlet, your funds are combined with funds from
                      other investors and given out as loans to the Government,
                      Banks and other reputable institutions. They pay interest
                      to the MMF for this every day and your portion of the
                      interest is passed on to you
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Can I monitor my saving goals and interest?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_24"]: !SectionVisibility.Section1_24,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_24 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_24 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Absolutely! The dashboards on the mobile app was designed
                      with this in mind. You can monitor the progress on your
                      goals and the returns on a daily basis. On the goal
                      dashboard you can see the following:
                    </p>
                    <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                      <li>
                        Balance: sum of your current deposits and accrued
                        interest
                      </li>
                      <li>
                        Funds invested: the total amount of deposits you current
                        have in the savings goal
                      </li>
                      <li>
                        Interest: the total amount and % of interest that you
                        have earned on the savings goal
                      </li>
                      <li>
                        Goal progress: the target amount of your savings goal
                        and the % completion that you have achieved
                      </li>
                    </ul>
                  </div>
                </div>{" "}
              </div>
            </SectionItem>
            <SectionItem id="#section6" title="Money market funds">
              {/* <div className=" space-y-8 ">
                <div className=" space-y-8 "> */}
              <div className=" space-y-4 md:space-y-8 ">
                <div className=" space-y-4 md:space-y-1 pt-2 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      What is a Money Market Fund?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_25"]: !SectionVisibility.Section1_25,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_25 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_25 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      A Money Market Fund (MMF) is a type of Unit Trust in which
                      funds are invested mostly in financial products that are
                      short term (repaid in less than 1 year) and issued by
                      Government and established institutions (called issuers).
                      Because of this, MMFs are considered low risk and you can
                      get your money back quickly (high liquidity).
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Why should I save and invest in a money market fund?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_26"]: !SectionVisibility.Section1_26,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_26 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_26 ? "hidden" : "visible"
                    }
                  >
                    <ul className="list-disc text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto pl-4 mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0 space-y-8 ">
                      <li>
                        They are managed by professional investment managers who
                        are government regulated and know the Kenya financial
                        markets so can identify better places to invest your
                        funds.
                      </li>
                      <li>
                        Your funds are invested across several different
                        financial products. This minimizes potential losses
                        since “your eggs are not kept in one basket”. This is
                        called “risk diversification”.
                      </li>
                      <li>
                        Since Unit Trusts pool funds from several investors,
                        they raise large sums and are able to negotiate for
                        higher interest rates that you will not be able to do on
                        your own.
                      </li>
                    </ul>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How do you I earn interest from a Money Market Fund?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_27"]: !SectionVisibility.Section1_27,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_27 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_27 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      When you invest in an MMF, your funds are combined with
                      funds from other investors and given out as loans to the
                      Government, Banks and other reputable institutions. They
                      pay interest to the MMF for this every day and your
                      portion of the interest is passed on to you.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      What money market funds can I select on Cashlet?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_28"]: !SectionVisibility.Section1_28,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_28 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_28 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Currently, there are 3 money market funds that you can
                      select from:
                    </p>
                    <div className="text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      <div className="border border-gray-600 ">
                        <table className="w-full table-auto divide-y divide-gray-600">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="px-2 py-3 border-r border-gray-600 text-left text-base font-bold text-black tracking-wider"
                              >
                                Name of Money market fund
                              </th>
                              <th
                                scope="col"
                                className="px-2  py-3 border-r border-gray-600  text-left text-base font-bold text-black tracking-wider"
                              >
                                Fund Manager
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y  divide-gray-600">
                            {/* <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">Kasha Money Market fund</td>
                              <td className="px-2 py-3 text-left">Alpha Africa Asset Managers</td>
                            </tr> */}
                            <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                Money Market fund (ICEA LION Money Market fund)
                              </td>
                              <td className="px-2 py-3 text-left">ICEA Lion</td>
                            </tr>
                            <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                Etica Money Market fund
                              </td>
                              <td className="px-2 py-3 text-left">
                                Etica Capital
                              </td>
                            </tr>
                            <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                Etica Fixed Income fund
                              </td>
                              <td className="px-2 py-3 text-left">
                                Etica Capital
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      We are in the process of onboarding additional fund
                      managers onto the Cashlet platform.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Where do money market funds invest my savings?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_29"]: !SectionVisibility.Section1_29,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_29 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_29 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      This will vary from depending on the money market fund
                      that you select but the distribution should give you are
                      fair idea of where you money goes to earn you interest.
                    </p>
                    <div className="text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      <div className="border border-gray-600 ">
                        <table className="w-full table-auto divide-y divide-gray-600">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="w-3/12 border-r border-gray-600  px-2 py-3 text-left text-base font-bold text-black tracking-wider"
                              >
                                What MMFs invest your money in
                              </th>
                              <th
                                scope="col"
                                className=" w-6/12 border-r border-gray-600  px-2  py-3  text-left text-base font-bold text-black tracking-wider"
                              >
                                Description
                              </th>
                              <th
                                scope="col"
                                className=" w-3/12 px-2  py-3  text-left text-base font-bold text-black tracking-wider"
                              >
                                What proportion of your money is invested
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y  divide-gray-600">
                            <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                Fixed Deposits{" "}
                              </td>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                These are investment account swith fixed
                                interest rates that are offered by Banks such as
                                NCBA, Equity, and Coop Bank.{" "}
                              </td>
                              <td className="px-2 py-3 text-left">40% </td>
                            </tr>
                            <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                Securities Issued by the govt of Kenya{" "}
                              </td>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                These includes treasury bills and notes issued
                                by Government of Kenya. These are basically
                                financial products that government uses to
                                borrow from investors when they need some quick
                                funds and in return, the Governemt pays interest
                                to the investors.{" "}
                              </td>
                              <td className="px-2 py-3 text-left">30% </td>
                            </tr>
                            <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                Commercial papers{" "}
                              </td>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                These are financial products issued by large
                                reputable companies in Kenya to borrow funds
                                from investor when they need some quick funds.
                                In return, these corporations pay interest to
                                investors.{" "}
                              </td>
                              <td className="px-2 py-3 text-left">25% </td>
                            </tr>
                            <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                Cash and demand deposits
                              </td>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                Tehes are normal current and savings accounts in
                                Banks such as NCBA, Equity, Coop Bank.
                              </td>
                              <td className="px-2 py-3 text-left">5% </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </SectionItem>
            <SectionItem id="#section7" title="Withdrawals">
              {/* <div className=" space-y-8 ">
                <div className=" space-y-8 "> */}
              <div className=" space-y-4 md:space-y-8 ">
                <div className=" space-y-4 md:space-y-1 pt-2 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      When can I withdraw my money?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_30"]: !SectionVisibility.Section1_30,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_30 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_30 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      You can deposit money into your account at any time.
                      Likewise, you can withdraw all or part of your funds at
                      any time. You have 100% flexibility, and there is no
                      lock-in period.
                    </p>
                  </div>
                  {/* Section1_31 taken already */}
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Can I choose any amount to withdraw per time?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_32"]: !SectionVisibility.Section1_32,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_32 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_32 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Yes, you can!
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Simply log into your account and click on the withdraw
                      option, select the goal that you want to withdraw from,
                      then input the amount you want to withdraw.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      Am I locked in?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_33"]: !SectionVisibility.Section1_33,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_33 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_33 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      No, saving and investing is a long-term proposition but
                      with Cashlet, you can withdraw whenever you like and
                      there's no minimum investment period. However, the minimum
                      suggested timeframe you should consider is 6 months.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How do I withdraw into my M-pesa account?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_34"]: !SectionVisibility.Section1_34,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_34 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_34 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Select “Transfers” on the menu time at the bottom of the
                      app and then click on the "Make a withdrawal" option.
                      Select the savings goal that you want to withdraw from.
                      Select the M-Pesa account that you want the funds
                      transferred into and then confirm the amount you want to
                      withdraw. Your funds will be transferred to your selected
                      M-Pesa instantly. Please note that there is a maximum
                      withdrawal limit of KES 10,000 per day.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      For security purposes, you can only withdraw into the
                      M-pesa account that you used to set up your Cashlet
                      account.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      How do I withdraw into my Bank account?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_34"]: !SectionVisibility.Section1_34,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_34 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_34 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Select “Transfers” on the menu time at the bottom of the
                      app and then click on the "Make a withdrawal" option.
                      Select the savings goal that you want to withdraw from.
                      Add or select the bank account that you want the funds
                      transferred into and then confirm the amount you want to
                      withdraw. Your funds will be transferred to your selected
                      bank account within 3 days (excluding weekends and
                      holidays). Unlike M-pesa withdrawals, there are no limits
                      on the amount you can withdraw via your bank account.
                    </p>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      For security reasons, you can only withdraw into only one
                      bank account that is in your name. When you add the
                      account the first time, you will need to validate that it
                      actually belongs to you. You can do this by either
                      uploading a picture of your debit card or your latest bank
                      statement showing your name and bank account number.
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      What is the minimum/maximum amount I can withdraw?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_35"]: !SectionVisibility.Section1_35,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_35 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={
                      SectionVisibility.Section1_35 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      The minimum amount you can withdraw will depend on the
                      money market fund that you are using for your saving
                      goals. It is as follows:
                    </p>
                    <div className="text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      <div className="border border-gray-600 ">
                        <table className="w-full table-auto divide-y divide-gray-600">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="px-2 py-3 border-r border-gray-600 text-left text-base font-bold text-black tracking-wider"
                              >
                                Name of fund
                              </th>
                              <th
                                scope="col"
                                className="px-2  py-3 border-r border-gray-600  text-left text-base font-bold text-black tracking-wider"
                              >
                                Fund Manager
                              </th>
                              <th
                                scope="col"
                                className="px-2  py-3 border-r border-gray-600  text-left text-base font-bold text-black tracking-wider"
                              >
                                Minimum amount
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y  divide-gray-600">
                            <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                DigiTrust
                              </td>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                ICEA Lion Asset Managment
                              </td>
                              <td className="px-2 py-3 text-left">KSH 50</td>
                            </tr>
                            <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                               Etica Money Market Fund
                              </td>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                Etica Capital
                              </td>
                              <td className="px-2 py-3 text-left">KSH 50</td>
                            </tr>
                            <tr>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                                Etica Income Fund
                              </td>
                              <td className="px-2 py-3 text-left border-r border-gray-600 ">
                              Etica Capital
                              </td>
                              <td className="px-2 py-3 text-left">KSH 50</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      Maximum withdrawal limit for M-pesa: KSH 10,000 per day{" "}
                      <br />
                      Maximum withdrawal limit for bank transfer: There are no
                      limits
                    </p>
                  </div>
                </div>{" "}
                <div className="border-b-4 border-transparent border-gray-50" />
                <div className=" space-y-1 ">
                  <div className="flex flex-row relative ">
                    <h3 className="text-base tracking-tight w-10/12 md:w-full font-bold max-w-xs md:max-w-full text-gray-900 sm:text-xl md:text-xl lg:text-2xl">
                      More questions?
                    </h3>
                    {isMobile ? (
                      <div>
                        <img
                          onClick={() => {
                            setSectionVisibility({
                              ["Section1_36"]: !SectionVisibility.Section1_36,
                            });
                          }}
                          src={
                            SectionVisibility.Section1_36 ? expand : collapse
                          }
                          alt=""
                          className="  absolute inset-y-1 right-2"
                        />
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={
                      SectionVisibility.Section1_36 ? "hidden" : "visible"
                    }
                  >
                    <p className=" text-base tracking-normal text-gray-500 sm:mt-5 sm:text-lg  sm:mx-auto mt-4 md:mt-5 md:text-lg lg:text-lg lg:mx-0">
                      You can reach out to us via our email:{" "}
                      {/* <a
                        className="text-primary-green underline font-semibold" href="malto:team@cashlet.co.ke">
                        team@cashlet.co.ke{' '}
                      </a>{' '} */}
                      <a
                        className="text-primary-green underline font-semibold"
                        href={`mailto:team@cashlet.co.ke?subject=${"Question about Cashlet"}&body=${""}`}
                      >
                        team@cashlet.co.ke{" "}
                      </a>{" "}
                      We’ll be glad to hear from you.
                    </p>
                  </div>
                </div>{" "}
              </div>
            </SectionItem>

            {/* <SectionItem id="#section8" title="TimeLines">
              <div className=" space-y-8 ">

              </div>
            </SectionItem> */}
          </section>
        </div>
        {/* -------------------------------------------------- */}
        {/* <!--Section container--> */}
        {/* <!--/Section container--></div> */}
      </div>
    </section>
  );
}

export default FAQPage;
