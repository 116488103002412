import React from 'react';
import SubscribeCard from '../../../components/Blog/SubcribeCard/SubscribeCard';
import ShareLinks from '../../../components/Blog/ShareLinks/ShareLinks';
import ReactMarkdown from 'react-markdown';
import Moment from 'react-moment';
import { getStrapiMedia } from '../../../utils/media';
import MetaTags from 'react-meta-tags';


function BlogDescriptionSection({ article }) {
  console.log("BlogDescriptionSection" + JSON.stringify(article[0].image[0].url));//DEBUG
  return (
    <div className="relative ">
      <MetaTags>
        <title>{article[0].title}</title>
        <meta name="og:description" content={article[0].description} />
        <meta property="og:title" content={article[0].title} />
        <meta property="og:image" content={article.length > 0 ? `${getStrapiMedia(article[0].image[0].url)}` : null} />
        <meta property="og:type" content="website"/>
        
        <meta name="twitter:description" content={article[0].description} />
        <meta name="twitter:title" content={article[0].title} />
        <meta name="twitter:image" content={article.length > 0 ? `${getStrapiMedia(article[0].image[0].url)}` : null}/>
        <meta name="twitter:card" content="summary_large_image"/>
      </MetaTags>
      <div className="py-10 bg-white">
        <div className=" md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl mx-auto px-4 w-full ">
          <div className="grid sm:grid-rows-none  sm:grid-cols-6  grid-cols-1  sm:h-auto gap-4 ">
            <div className=" sm:col-span-4 col-span-1 ">
              <div className="space-y-3">
                <div className="flex  items-center space-x-2">
                  <div className="flex  border-2 border-primary-blue rounded py-1 px-2">
                    <span className=" text-xs font-semibold text-primary-blue uppercase">important</span>
                  </div>
                  <p className="text-sm text-gray-600 ">{article[0].read_duration} min read</p>
                </div>
                <h3 className="text-5xl tracking-tight font-bold sm:max-w-2xl md:max-w-4xl lg:max-w-4xl text-gray-900  md:text-4xl lg:text-3xl xl:text-5xl">
                  {article[0].title}
                </h3>
                <p className="  text-base text-gray-600  sm:text-lg sm:mx-auto  md:text-xl lg:text-lg xl:text-xl lg:mx-0">
                  {article[0].description}
                </p>
              </div>
            </div>
            <div className="  col-span-2 sm:relative">
              <div className="flex flex-col  space-y-4 sm:absolute sm:bottom-0 sm:right-0">
                {article.author && (
                  <div className="flex items-center space-x-2">
                    <span className="text-base  font-bold text-primary-gray-dark  sm:text-lg sm:mx-auto  md:text-xl lg:text-lg xl:text-xl lg:mx-0">
                      {article.author.name}
                    </span>
                    {article.author.picture && (
                      <img
                        className="rounded-full w-10 h-10"
                        src={getStrapiMedia(article.author.picture)}
                        alt={article.author.picture.name}
                      />
                    )}
                  </div>
                )}
                <div className="flex flex-col items-end space-y-1">
                  <div className=" flex items-center space-x-2 ">
                    <span>
                      {' '}
                      <Moment format="MMM DD, YYYY">{article[0].published_at}</Moment>
                    </span>
                    <svg
                      className="h-5 w-5 text-primary-purple-dark fill-current"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.5449 2.00008H13.2116C13.3884 2.00008 13.558 2.07032 13.683 2.19534C13.808 2.32037 13.8783 2.48994 13.8783 2.66675V13.3334C13.8783 13.5102 13.808 13.6798 13.683 13.8048C13.558 13.9298 13.3884 14.0001 13.2116 14.0001H1.21159C1.03478 14.0001 0.865208 13.9298 0.740184 13.8048C0.61516 13.6798 0.544922 13.5102 0.544922 13.3334V2.66675C0.544922 2.48994 0.61516 2.32037 0.740184 2.19534C0.865208 2.07032 1.03478 2.00008 1.21159 2.00008H3.87826V0.666748H5.21159V2.00008H9.21159V0.666748H10.5449V2.00008ZM9.21159 3.33341H5.21159V4.66675H3.87826V3.33341H1.87826V6.00008H12.5449V3.33341H10.5449V4.66675H9.21159V3.33341ZM12.5449 7.33341H1.87826V12.6667H12.5449V7.33341Z" />
                    </svg>
                  </div>
                  <div className=" items-center">
                    <p>
                      In category: 
                      <span className="text-gray-900 font-bold capitalize">
                        {article.category && article[0].category.name} {article[0].category.name}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-2 bg-white">
        <div className=" md:max-w-2xl lg:max-w-4.75xl xl:max-w-6xl  2xl:max-w-screen-xl mx-auto px-4  pb-16 ">
          <div className="grid grid-cols-6 gap-8 ">
            <div className="col-span-6 sm:col-span-4 space-y-12 ">
              <div className="py-4">
                <div
                  className="bg-cover bg-center w-full h-88  py-6 mb-4 rounded-3xl"
                  // style={{ backgroundImage: `url(${getStrapiMedia(article.image[0].url)})` }}
                  style={{ backgroundImage: article.length > 0 ? `url(${getStrapiMedia(article[0].image[0].url)})` : null }}

                ></div>
                {/* share */}
                <ReactMarkdown
                  children={article[0].content}
                  escapeHtml={false}
                />
                {/* <ReactMarkdown>{article[0].content}</ReactMarkdown> */}
              </div>
              <div className="flex items-center w-full shadow border rounded-2xl ">
                <div className="w-4/5 px-4 py-2">
                  <ShareLinks titleClassName="text-primary-purple font-bold text-lg" />
                </div>
                <div className="w-1/5 border-l-2 px-4 py-2 ">
                  <div className="flex  justify-between items-center">
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http:www.w3.org/2000/svg">
                      <path
                        d="M11.0757 15.2711L5.96571 12.4841C5.47521 12.971 4.85153 13.3018 4.1733 13.4347C3.49508 13.5677 2.79267 13.4969 2.15464 13.2312C1.5166 12.9655 0.971504 12.5169 0.588062 11.9419C0.204619 11.3669 0 10.6912 0 10.0001C0 9.30898 0.204619 8.63331 0.588062 8.0583C0.971504 7.48329 1.5166 7.03468 2.15464 6.76901C2.79267 6.50335 3.49508 6.43253 4.1733 6.56548C4.85153 6.69844 5.47521 7.02921 5.96571 7.51611L11.0757 4.72911C10.9005 3.90685 11.027 3.04897 11.4323 2.31236C11.8376 1.57576 12.4945 1.00964 13.2828 0.717544C14.0712 0.425446 14.9383 0.426885 15.7257 0.721597C16.5131 1.01631 17.1681 1.5846 17.5709 2.32255C17.9737 3.0605 18.0975 3.91879 17.9195 4.74046C17.7415 5.56214 17.2737 6.2923 16.6016 6.79741C15.9295 7.30252 15.0981 7.54884 14.2593 7.49131C13.4206 7.43378 12.6305 7.07625 12.0337 6.48411L6.92371 9.27111C7.02565 9.75177 7.02565 10.2485 6.92371 10.7291L12.0337 13.5161C12.6305 12.924 13.4206 12.5664 14.2593 12.5089C15.0981 12.4514 15.9295 12.6977 16.6016 13.2028C17.2737 13.7079 17.7415 14.4381 17.9195 15.2598C18.0975 16.0814 17.9737 16.9397 17.5709 17.6777C17.1681 18.4156 16.5131 18.9839 15.7257 19.2786C14.9383 19.5733 14.0712 19.5748 13.2828 19.2827C12.4945 18.9906 11.8376 18.4245 11.4323 17.6879C11.027 16.9513 10.9005 16.0934 11.0757 15.2711Z"
                        fill="#4B5E7F"
                      />
                    </svg>

                    <p className="text-primary-purple text-base">Copy link</p>
                  </div>
                </div>
              </div>
              {/* Tags */}
              <div className="flex items-center w-full space-x-4 ">
                <p className="text-black text-base">Tags:</p>
                <div className="inline-flex flex-wrap space-x-4 space-y-4 lg:space-y-0">
                  {article.tags &&
                    article.tags.map((tag) => (
                      <button
                        className="
                  flex items-center justify-center px-3 py-2 text-sm xl:text-base font-normal text-primary-gray-dark bg-primary-gray-semi-light  rounded-lg focus:outline-none focus:bg-primary-green-dark focus:text-white"
                      >
                        {tag.name}
                      </button>
                    ))}{' '}
                </div>
              </div>
            </div>
            <div className="col-span-2">
              <div className="py-2 space-y-4 sticky inset-0 hidden max-h-full lg:h-auto overflow-x-hidden overflow-y-auto lg:overflow-y-hidden lg:block mt-0 my-8">
                {/* card */}
                <SubscribeCard />
                <ShareLinks />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogDescriptionSection;
