import React from 'react';
import classNames from 'classnames';

function ShareLinks({ titleClassName }) {
  return (
    <div className="w-full flex  justify-between items-center">
      <p className={classNames('text-gray-500 text-base', titleClassName)}>Share with friends</p>
      <div className="flex items-center ">
        <a
          href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
          className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
          type="button"
          target="_blank"
        >
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#20E083" fillOpacity="0.12" />
            <path
              d="M18.2 10.002V7.5H16.139C14.249 7.5 13.061 8.94 13.061 11.001V12.504H11V14.997H13.061V21H15.626V14.997H17.687L18.2 12.504H15.626V11.496C15.626 10.506 16.148 10.002 17.174 10.002H18.2Z"
              fill="#00C673"
            />
          </svg>
        </a>
        <a
           href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
          //  href={`https://twitter.com/intent/tweet?url=https://www.youtube.com/watch?v=U3aXWizDbQ4&ab_channel=Fireship`}
           
          className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
          type="button"
          target="_blank"
        >
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#20E083" fillOpacity="0.12" />
            <path
              d="M18.5061 7.50001C17.992 7.49893 17.4843 7.61424 17.0211 7.83731C16.5579 8.06038 16.1512 8.38541 15.8315 8.78805C15.5118 9.19068 15.2874 9.66046 15.1751 10.1622C15.0628 10.6639 15.0655 11.1845 15.1831 11.685C13.8269 11.6177 12.5001 11.2657 11.2889 10.6521C10.0777 10.0384 9.00912 9.17673 8.15269 8.12308C7.71139 8.87221 7.57524 9.76241 7.77243 10.6092C7.96963 11.456 8.48506 12.1944 9.21192 12.6715C8.6704 12.6585 8.13987 12.516 7.66461 12.2562V12.2873C7.66388 13.0759 7.93677 13.8402 8.43674 14.45C8.9367 15.0598 9.63275 15.4773 10.4061 15.6312C9.90189 15.7697 9.37261 15.791 8.85884 15.6935C9.07729 16.3711 9.50198 16.9636 10.0736 17.3881C10.6451 17.8126 11.3351 18.0479 12.0469 18.0611C11.3397 18.6166 10.53 19.0272 9.66401 19.2696C8.79803 19.5119 7.89277 19.5812 7 19.4735C8.55858 20.473 10.3719 21.0029 12.2235 21C18.4958 21 21.9227 15.8077 21.9227 11.3008V10.8646C22.5874 10.3816 23.1607 9.78372 23.6154 9.09924C23.0027 9.36924 22.3484 9.55616 21.6527 9.63924C22.3588 9.21347 22.8988 8.54885 23.1584 7.74924C22.4938 8.14385 21.7669 8.42424 20.9881 8.58001C20.6689 8.23866 20.283 7.96662 19.8542 7.78082C19.4255 7.59502 18.963 7.49944 18.4958 7.50001H18.5061Z"
              fill="#00C673"
            />
          </svg>
        </a>
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
          className="font-normal items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
          type="button"
          target="_blank"
        >
          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="30" height="30" rx="15" fill="#20E083" fillOpacity="0.12" />
            <path
              d="M12.0635 13.0501H9.16615V21.4617H12.0738V13.0501H12.0635ZM19.1665 12.8528C17.7958 12.8528 17.0896 13.507 16.695 14.0574L16.56 14.2444V13.0501H13.6627L13.6731 13.559V21.4617H16.56V16.7678C16.5428 16.5503 16.5708 16.3316 16.6424 16.1254C16.714 15.9193 16.8275 15.7302 16.9758 15.5702C17.1242 15.4102 17.3041 15.2826 17.5042 15.1956C17.7043 15.1086 17.9203 15.0641 18.1385 15.0647C19.1354 15.0647 19.5612 15.7501 19.6027 16.7886V21.4617H22.5V16.6432C22.5 14.0574 21.0773 12.8528 19.1769 12.8528H19.1665ZM10.6408 9.00012C9.64385 9.00012 9 9.6232 9 10.454C9 11.212 9.56077 11.8247 10.4331 11.9078H10.62C11.6273 11.9078 12.2504 11.264 12.2504 10.454C12.24 9.6232 11.6273 9.00012 10.6408 9.00012Z"
              fill="#00C673"
            />
          </svg>
        </a>
      </div>
    </div>
  );
}

export default ShareLinks;
