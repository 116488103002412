import React, { useEffect } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import MainLayout from './layouts/Main.layout';
import BlogLayout from './layouts/Blog.layout';
import history from './utils/history';
import { alertActions } from './_store/_actions';

import ReactGA from 'react-ga'

ReactGA.initialize('G-RQDKZ14WHJ',[])

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location, action) => {
      // clear alert on location change
      dispatch(alertActions.clear());
    });
  }, [dispatch]);

  return (
    <>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/blog" render={(props) => <BlogLayout {...props} />} />
          <Route path="/" render={(props) => <MainLayout {...props} />} />
          {/* add redirect for first page */}
          <Redirect from="*" to="/" />
        </Switch>
      </ConnectedRouter>
    </>
  );
}

export default App;
