import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Moment from 'react-moment';
import { getStrapiMedia } from '../../../utils/media'

function BlogItem({ article, className = 'col-span-3 ' }) {
  const imageUrl =
    process.env.NODE_ENV !== 'development' ? article.image.url : process.env.REACT_APP_BACKEND_URL + article.image.url;
  // let imageUrl = article.image[0].url || ''
  // console.log("BlogItem",article);
  // console.log("lifeCycle",article.image.length);
  return (
    <div className={classNames('space-y-4', className)}>
      <Link to={`/blog/${article.slug}`} className="space-y-4">
        <div
          className=" bg-contain bg-no-repeat  bg-top w-full h-52 p-4  rounded-3xl"
          //GAVE ME A WEEK'S LOG HEADACHE
          style={{ 
            // backgroundSize:"cover",
            backgroundImage: article.image.length>0?`url(${getStrapiMedia(article.image[0].url)})`:null }}
        ></div>
        <div className="space-y-3">
          <h3 className="text-lg xl:text-xl font-bold text-gray-900 blog-item-title">{article.title}</h3>
          <p className="text-base text-primary-gray-lighter  sm:mx-auto tracking-tight  lg:mx-0 blog-item-description">
            {article.description}
          </p>
        </div>
        <div className="flex justify-between items-center text-sm text-gray-700">
          <div className=" inline-flex items-center ">
            <p>
              In: <span className="text-gray-900 font-bold capitalize">{article.category.name}</span>
            </p>
          </div>
          <div className=" inline-flex items-center space-x-3">
            <p>{article.read_duration} min </p>
            <p className="text-gray-900 font-bold"> • </p>
            <p>
              {' '}
              <Moment format="MMM DD, YYYY">{article.published_at}</Moment>
            </p>
          </div>
        </div>
      </Link>

      <div 
      className='border'>

      </div>
    </div>
  );
}

export default BlogItem;
