import React from "react";
import { Link } from "react-router-dom";
import bg9 from "../../../assets/img/bg/bg-9.svg";
import googlePlay from "../../../assets/img/googlePlay.svg";
import Frame1644 from "../../../assets/img/Frame1644.png";
// import StoresIcon from '../../assets/img/combo.png'
import StoresIcon from "../../../assets/img/combo.png";
import AppStoreIcon from "../../../assets/img/apple.png";
import ReactModal from "react-modal";
import CloseButton from "../../../assets/img/closeButton.png";

import { RegistrationModal } from "../../emailModal/emailmodal";

function BannerCard() {
  const [WaitListModal, setWaitListModal] = React.useState(false);

  function ChangeHandler() {
    setWaitListModal(!WaitListModal);
    // console.log("pressed")
  }

  return (
    <div className="pt-10 w-full flex flex-col justify-center items-center">
      <RegistrationModal
        visibility={WaitListModal}
        handleChange={ChangeHandler}
      />

      <div className="max-w-lg bg-white border-1 border-primary-gray-dark rounded-3xl overflow-hidden mx-auto">
        <div
          className="py-6 px-8 bg-no-repeat bg-bottom bg-contain"
          style={{ backgroundImage: `url(${bg9})` }}
        >
          <div className="flex justify-between relative ">
            <div className=" space-y-1 pt-2">
              <h1 className="text-2xl text-primary-green-dark font-bold">
                Start using Cashlet app
              </h1>
              <h3 className="w-9/12 text-lg tracking-tight text-black font-bold">
                The modern way to save and grow your money{" "}
              </h3>
              <div className="pt-10 2xl:pt-20">
                {/* <Link to="/" className="">
                  <img
                    src={googlePlay}
                    className=" h-10   rounded-md shadow border border-black bounce-top-icons"
                    alt="i"
                  />
                </Link>{' '} */}

                {/* 1_0223 */}
                <div className="flex invisible md:visible flex-col md:flex-row gap-3 w-full">
                  <div className="flex flex-row pb-5 cursor-pointer ">
                    <div
                      onClick={() => {
                        window.location.href =
                          "https://www.clkmg.com/Cashletapp/pswebsite";
                      }}
                      className=" flex flex-row rounded w-ful py-1 shadow-lg overflow-hidden border-gray-400 border text-black font-bold bg-white"
                    >
                      <img
                        className="h-12 w-17 justify-center"
                        src={StoresIcon}
                      />

                      <div className="flex-column self-center pr-4">
                        <div className=" align-text-bottom my-auto font-medium tracking-tight text-2xs">
                          Get it on
                        </div>
                        <div className=" align-text-bottom my-auto tracking-tight text-xs">
                          Google Play
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row pb-5 cursor-pointer ">
                    <div
                      onClick={() => {
                       window.open(
                         "https://www.clkmg.com/Cashletapp/aswebsite",
                         "_blank"
                       );
                      }}
                      className=" flex flex-row rounded w-full py-1 shadow-lg overflow-hidden border-gray-400 border text-black font-bold bg-white"
                    >
                      <img
                        className="h-7 w-7 mt-2 mx-3 justify-center"
                        src={AppStoreIcon}
                      />

                      <div className="flex-column self-center pr-4">
                        <div className=" align-text-bottom my-auto font-medium tracking-tight text-2xs">
                          Get it on
                        </div>
                        <div className=" align-text-bottom my-auto tracking-tight text-xs">
                          App Store
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" ">
              <div className="absolute left-1/2 top-9">
                <img src={Frame1644} className="w-36 h-full " alt="i" />
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BannerCard;
